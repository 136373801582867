import * as Yup from 'yup';

export const siteFormValidation = Yup.object().shape({
  name: Yup.string()
    .required()
    .min(5, 'Username is too short - should be 4 chars minimum'),
  domain: Yup.string()
    .required()
    .min(5, 'Username is too short - should be 4 chars minimum'),
  category: Yup.array().required(),
  subCategory: Yup.array().required(),
  serviceType: Yup.string().required(),
  blockedTraffic: Yup.array(),
});
