/**
 * External dependencies
 */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { SITE_EDIT, SITE_CREATE, SITE_DELETE } from 'utils/mutations/allSites';

/**
 * Internal dependencies
 */
import { ALL_SITES, SITE_BY_ID } from '../queries/allSites';

export function useAllSites() {
  const { data, loading, error } = useQuery(ALL_SITES, {
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
  };
}

export function useSiteById(id) {
  const [getSiteByIdQuery, { data, loading, error, refetch }] = useLazyQuery(
    SITE_BY_ID,
    {
      variables: {
        id: id,
      },
      fetchPolicy: 'network-only',
    }
  );

  return {
    getSiteByIdQuery,
    data,
    loading,
    error,
    refetch,
  };
}

export function useSiteCreate() {
  const [createSite, { data, loading, error }] = useMutation(SITE_CREATE, {
    refetchQueries: [{ query: ALL_SITES }],
  });

  return {
    createSite,
    data,
    loading,
    error,
  };
}

export function useSiteEdit() {
  const [editSite, { data, loading, error }] = useMutation(SITE_EDIT);

  return {
    editSite,
    data,
    loading,
    error,
  };
}

export function useSiteDelete() {
  const [deleteSite, { data, loading, error }] = useMutation(SITE_DELETE, {
    refetchQueries: [{ query: ALL_SITES }],
  });

  return {
    deleteSite,
    data,
    loading,
    error,
  };
}
