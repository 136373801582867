import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../components/DashboardNavbar';
import Footer from '../../../components/Footer';
import Card from '@mui/material/Card';
import DataTable from 'blocks/Tables/DataTable';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDButton from '../../../components/MDButton';
import breakpoints from '../../../assets/theme/base/breakpoints';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Stack } from '@mui/material';
import useAllVouchers from '../../../utils/hooks/use-allVouchers';
import LoadingBasic from '../../../components/LoadingBasic';
import columns from './columns';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import useAddVoucher from '../../../utils/hooks/useAddVoucher';
import { toast } from 'react-toastify';

function Vouchers() {
  const { data, loading, error } = useAllVouchers();
  const allVouchers = data?.allVouchers.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  function tableData(tabValue) {
    switch (tabValue) {
      case 1:
        return allVouchers.filter((row) => row.redeemedAt);
      case 2:
        return allVouchers.filter((row) => !row.redeemedAt);
      default:
        return allVouchers;
    }
  }

  const [open, setOpen] = useState(false);
  const [tokens, setTokens] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTokens('');
    setOpen(false);
  };

  const handleChange = (newValue) => {
    if (!newValue) setTokens(newValue);
    const updatedValue = parseInt(newValue);
    if (updatedValue) {
      setTokens(updatedValue);
    }
  };

  const { addVoucher, loading: loadingAdded } = useAddVoucher();

  const handleAdd = () => {
    const res = addVoucher({ variables: { value: tokens } });
    toast.promise(res, {
      pending: 'Loading',
      success: {
        render({ data }) {
          return `${data.data.addVoucher.code} added`;
        },
      },
      error: {
        render({ data }) {
          return data.message;
        },
      },
    });
    handleClose();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={3} maxWidth={950} minHeight={{ lg: '85vh' }} mx="auto">
        <Card sx={{ minHeight: '60vh' }}>
          <MDBox
            mt={-3}
            mx={3}
            bgColor="info"
            borderRadius="lg"
            minHeight="80px"
            textAlign="center"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MDTypography variant="h3" color="light" fontWeight="regular">
              Vouchers
            </MDTypography>
          </MDBox>
          <Stack
            mx={3}
            my={3}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <MDButton
              variant="contained"
              color="info"
              onClick={handleClickOpen}
            >
              Add voucher
            </MDButton>
          </Stack>
          <MDBox
            pl={5}
            pr={5}
            lineHeight={2}
            display="flex"
            alignItems="center"
          >
            {/*<Grid item xs={12} sm={8} lg={4}>*/}
            <MDBox minWidth={{ xs: '100%', sm: 420 }}>
              <Tabs
                // centered
                // variant="fullWidth"
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
              >
                <Tab label="  ALL  " />
                <Tab label="Redeemed" />
                <Tab label="Unused" />
              </Tabs>
            </MDBox>
            {/*</Grid>*/}
          </MDBox>
          {loading ? (
            <LoadingBasic />
          ) : (
            <DataTable
              isSorted={false}
              columnsArray={columns}
              tableData={tableData(tabValue)}
              canSearch
              pagination={{ variant: 'gradient', color: 'success' }}
              noEndBorder
            />
          )}
        </Card>
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        // sx={{
        //   '& .MuiDialog-container': {
        //     '& .MuiPaper-root': {
        //       width: '100%',
        //       maxWidth: '500px', // Set your width here
        //     },
        //   },
        // }}
      >
        <DialogTitle>Create new voucher</DialogTitle>
        <DialogContent>
          {/*<DialogContentText>*/}
          {/*  To subscribe to this website, please enter your email address here.*/}
          {/*  We will send updates occasionally.*/}
          {/*</DialogContentText>*/}
          <TextField
            autoFocus
            margin="dense"
            id="tokens"
            label="Tokens"
            type="text"
            fullWidth
            variant="standard"
            value={tokens}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button onClick={handleAdd}>&nbsp;add&nbsp;</Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default Vouchers;
