/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_SITES = gql`
  query AllSitesQuery {
    allSites {
      edges {
        _id
        updatedAt
        name
        domain
        category
        subCategory
        preferredChannels
        serviceType
        banners {
          type
          defaultImageUrl
          defaultRedirectUrl
        }
      }
    }
  }
`;

export const SITE_BY_ID = gql`
  query GetSiteById($id: ID!) {
    getSiteById(id: $id) {
      _id
      updatedAt
      name
      domain
      category
      subCategory
      preferredChannels
      serviceType
      banners {
        type
        defaultImageUrl
        defaultRedirectUrl
      }
    }
  }
`;
