import { useNavigate } from 'react-router-dom';
import MDButton from '../../../components/MDButton';
import React from 'react';
import { useSiteList } from '../useSiteListContext';
import { Stack } from '@mui/material';
import MDBadge from '../../../components/MDBadge';

export const useSitesColumns = () => {
  const navigate = useNavigate();
  const { deleteConfirmation } = useSiteList();
  const columns = [
    { Header: 'name', accessor: 'name', width: 'auto' },
    { Header: 'domain', accessor: 'domain', width: 'auto' },
    {
      Header: 'category',
      accessor: 'category',
      Cell: ({ row }) => {
        return (
          <Stack spacing={0.25}>
            {row.original.category.map((item) => (
              <MDBadge key={item} badgeContent={item} container />
            ))}
          </Stack>
        );
      },
      width: 'auto',
    },
    { Header: 'service type', accessor: 'serviceType', width: 'auto' },
    {
      Header: '',
      accessor: 'actions',
      // width: '',
      isSorted: false,
      Cell: ({ row }) => {
        return (
          <div>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              style={{ marginRight: 5 }}
              onClick={() => navigate(`/sites/edit/${row.original._id}`)}
            >
              Edit
            </MDButton>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              style={{ marginRight: 5 }}
              onClick={() => {
                deleteConfirmation(row.original._id);
              }}
            >
              Delete
            </MDButton>
          </div>
        );
      },
    },
  ];

  return columns;
};
