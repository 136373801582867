import {
  Card,
  CardActions,
  CardContent,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../components/DashboardNavbar';
import MDInput from 'components/MDInput';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';

const PaymentConfig = () => {
  const [billKey, setBillKey] = useState('');

  const handleChange = (e) => {
    setBillKey(e.target.value);
  };

  const handleSave = () => {
    if (billKey) alert(`Key ${billKey} saved!`);
    else alert('Key empty!');
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDTypography variant="h4">Payment configuration</MDTypography>
        <Card sx={{ maxWidth: 600 }}>
          <CardContent>
            <Stack direction="row" spacing={1}>
              <MDBadge badgeContent="CCBill API KEY" />
              <MDInput fullWidth="true" onChange={handleChange} />
              <Tooltip title="Save" placement="top">
                <IconButton onClick={handleSave}>
                  <SaveIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Stack>
          </CardContent>
        </Card>
      </DashboardLayout>
    </>
  );
};

export default PaymentConfig;
