import MDTypography from '../../../../components/MDTypography';
import React from 'react';

const columns = [
  {
    Header: 'date',
    accessor: (transaction) => {
      const transDate = new Date(transaction.date);
      return (
        <MDTypography variant="caption">
          {transDate.toLocaleDateString()}
        </MDTypography>
      );
    },
  },
  { Header: 'amount', accessor: 'amount' },
  { Header: 'type', accessor: 'label' },
  { Header: 'status', accessor: 'status' },
];

export default columns;
