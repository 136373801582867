export const bundlesDummyData = [
  {
    _id: '62e3cd3c8f958f112cb6faf8',
    createdAt: '2022-07-29T12:06:20.225Z',
    category: 'MODEL',
    label: 'PRO',
    visible: true,
    price: 85,
    rate: 5,
    bonus: 10,
    activeFrom: '2022-07-29T07:32:57.000Z',
    activeUntil: '2028-07-29T07:34:03.000Z',
  },
  {
    _id: '62e3cc20e556e69b33d03b81',
    createdAt: '2022-07-29T12:06:20.225Z',
    category: 'MODEL',
    label: 'PRO',
    visible: true,
    price: 80,
    rate: 5,
    bonus: 10,
    activeFrom: '2022-07-29T07:32:57.000Z',
    activeUntil: null,
  },
  {
    _id: '62e3bad2d49b8bff5fd81b4b',
    createdAt: '2022-07-29T12:06:20.225Z',
    category: 'STUDIO',
    label: 'ELITE',
    visible: true,
    price: 97,
    rate: 5,
    bonus: 10,
    activeFrom: '2022-07-29T07:32:57.000Z',
    activeUntil: null,
  },
  {
    _id: '62e3ba6acdaec33999b9b963',
    createdAt: '2022-07-29T12:06:20.225Z',
    category: 'MODEL',
    label: 'ELITE',
    visible: true,
    price: 99,
    rate: 5,
    bonus: 10,
    activeFrom: '2022-07-29T07:32:57.000Z',
    activeUntil: '2028-07-29T07:34:03.000Z',
  },
  {
    _id: '62e3ba0f85373c9f558c9464',
    createdAt: '2022-07-29T12:06:20.225Z',
    category: 'MODEL',
    label: 'ELITE',
    visible: true,
    price: 100,
    rate: 5,
    bonus: 10,
    activeFrom: '2022-07-29T07:32:57.000Z',
    activeUntil: '2028-07-29T07:34:03.000Z',
  },
  {
    _id: '62e3b6f3b7116a26c7f54719',
    createdAt: '2022-07-29T12:06:20.225Z',
    category: 'STUDIO',
    label: 'REGULAR',
    visible: false,
    price: 25,
    rate: 5,
    bonus: 10,
    activeFrom: '2022-07-29T07:32:57.000Z',
    activeUntil: '2028-07-29T07:34:03.000Z',
  },
  {
    _id: '62e38c747c69bbc90dd1de76',
    createdAt: '2022-07-29T07:30:40.000Z',
    category: 'MODEL',
    label: 'STARTER',
    visible: true,
    price: 15,
    rate: 10,
    bonus: 10,
    activeFrom: '2022-07-29T07:32:57.000Z',
    activeUntil: '2028-07-29T07:34:03.000Z',
  },
];
