import { gql, useMutation } from '@apollo/client';
import { GET_USER } from '../queries/getUser';
import { GET_USER_TRANSACTIONS } from '../queries/queries';
import { ALL_USERS } from '../queries/allUsers';

export const EDIT_USER_PROFILE = gql`
  mutation editUserProfile($id: ID!, $input: EditUserInput) {
    editUser(id: $id, input: $input) {
      node {
        _id
        username
        profile {
          registrationWizard
          name
          avatar
          gender
          birthDate
        }
        socialProfile {
          instagram
          twitter
          snapchat
          onlyfans
        }
        status {
          onlineAdminDash
          onlineUserDash
        }
        blockCountries
        workingSites
        suspended
      }
      message
    }
  }
`;

const GET_CREATE_CAMPAIGN = gql`
  mutation createCampaign($input: CampaignInput) {
    createCampaign(input: $input) {
      message
      node {
        _id
        updatedAt
        category
        subCategory
        preferredChannels
        serviceType
        duration {
          startDate
          endDate
        }
        userId
        tokens
      }
    }
  }
`;

const EDIT_USER_TOKENS = gql`
  mutation TokensUser($tokens: Int, $actionType: String, $price: Int) {
    tokensUser(tokens: $tokens, actionType: $actionType, price: $price) {
      node {
        _id
        username
        tokens
      }
      message
    }
  }
`;

const ADMIN_EDIT_USER_TOKENS = gql`
  mutation AdminTokensUser($tokens: Int, $actionType: String, $userId: ID!) {
    adminTokensUser(tokens: $tokens, actionType: $actionType, userID: $userId) {
      node {
        _id
        username
        tokens
      }
      message
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation userForgotPassword($email: String) {
    userForgotPassword(email: $email) {
      message
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation UserResetPassword(
    $resetToken: String
    $newPassword: String
    $verifyPassword: String
  ) {
    userResetPassword(
      resetToken: $resetToken
      newPassword: $newPassword
      verifyPassword: $verifyPassword
    ) {
      message
    }
  }
`;

export function userResetPassword() {
  const [useResetPassword, { data, loading, error }] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation(RESET_PASSWORD);

  return {
    useResetPassword,
    data,
    loading,
    error,
  };
}

export function userForgotPassword() {
  const [useForgotPassword, { data, loading, error }] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation(FORGOT_PASSWORD);

  return { useForgotPassword, data, loading, error };
}

export function useEditUser() {
  const [editUser, { data, loading, error }] = useMutation(EDIT_USER_PROFILE);

  return { editUser, data, loading, error };
}

export function useEditUserTokens() {
  const [editUserTokens, { data, loading, error }] = useMutation(
    EDIT_USER_TOKENS,
    {
      refetchQueries: [{ query: GET_USER }, { query: GET_USER_TRANSACTIONS }],
    }
  );

  return { editUserTokens, data, loading, error };
}

export function useAdminEditUserTokens() {
  const [adminEditUserTokens, { data, loading, error }] = useMutation(
    ADMIN_EDIT_USER_TOKENS,
    {
      refetchQueries: [
        { query: GET_USER },
        { query: GET_USER_TRANSACTIONS },
        { query: ALL_USERS },
      ],
      fetchPolicy: 'network-only',
    }
  );

  return { adminEditUserTokens, data, loading, error };
}

export function useCreateCampaign() {
  const [createCampaign, { data, loading, error }] = useMutation(
    GET_CREATE_CAMPAIGN,
    {
      refetchQueries: [{ query: GET_USER_TRANSACTIONS }],
    }
  );

  return {
    createCampaign,
    data,
    loading,
    error,
  };
}
