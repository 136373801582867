/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_CAMPAIGNS = gql`
  query AllCampaigns {
    allCampaigns {
      edges {
        _id
        updatedAt
        category
        subCategory
        preferredChannels
        serviceType
        duration {
          startDate
          endDate
        }
        userId
        userName
        tokens
        banners {
          siteId
          siteName
          type
          imageUrl
          redirectUrl
        }
      }
    }
  }
`;

export const CAMPAIGN_BY_ID = gql`
  query GetCampaignById($getCampaignByIdId: ID!) {
    getCampaignById(id: $getCampaignByIdId) {
      _id
      updatedAt
      category
      subCategory
      preferredChannels
      serviceType
      duration {
        startDate
        endDate
      }
      userId
      tokens
      banners {
        siteId
        siteName
        siteDomain
        type
        imageUrl
        redirectUrl
      }
    }
  }
`;
