export default [
  {
    id: 1,
    question: 'Q1',
    answer: 'A1',
    action: '',
    shadow: false,
  },
  {
    id: 2,
    question: 'Q2',
    answer: 'A2',
    action: '',
    shadow: true,
  },
  {
    id: 3,
    question: 'Q3',
    answer: 'A3',
    action: '',
    shadow: false,
  },
  {
    id: 4,
    question: 'Q4',
    answer: 'A4',
    action: '',
    shadow: true,
  },
];
