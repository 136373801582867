import { Card, CardContent, CardHeader } from '@mui/material';
import MDTypography from 'components/MDTypography';
import React from 'react';

function UsersDetailCard({ header, content }) {
  return (
    <Card sx={{ boxShadow: 4 }}>
      <CardHeader
        title={
          <MDTypography variant="subtitle2" lineHeight={1}>
            {header}
          </MDTypography>
        }
        sx={{ borderBottom: '1px #F0F2F5 solid', pb: 1 }}
      />
      <CardContent sx={{ mb: -1.5, minHeight: 92 }}>{content}</CardContent>
    </Card>
  );
}

export default UsersDetailCard;
