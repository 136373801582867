/**
 * External dependencies
 */
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { GET_USER_TRANSACTIONS } from '../queries/getUserTransactions';

export default function useGetUserTransactions(userId) {
  const { data, loading, error } = useQuery(GET_USER_TRANSACTIONS, {
    variables: { userId },
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
  };
}
