/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import BannerTemplate from './BannerTemplate';
import Grid from '@mui/material/Grid';

const bannerLocations = [
  {
    type: 'header',
  },
  {
    type: 'sidebar',
  },
  {
    type: 'sidebar2',
  },
  {
    type: 'footer',
  },
];

function Banners() {
  return (
    <MDBox>
      <Grid container rowSpacing={2} columnSpacing={20}>
        {bannerLocations.map((item, idx) => {
          return (
            <Grid
              key={idx}
              item
              xs={12}
              md={['header', 'footer'].includes(item.type) ? 12 : 6}
            >
              <BannerTemplate {...item} />
            </Grid>
          );
        })}
      </Grid>
    </MDBox>
  );
}

export default Banners;
