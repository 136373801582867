/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'blocks/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../components/DashboardNavbar';
import Footer from 'components/Footer';
import DataTable from '../../components/DataTable';

// Data
import React from 'react';
import ShieldMain from '../../components/ShieldMain/ShieldMain';
import { useSitesColumns } from './hooks/useSitesColumns';
import { useNavigate } from 'react-router-dom';
import { SiteListProvider, useSiteList } from './useSiteListContext';
import ConfirmationModal from './ConfirmationModal';
import LoadingBasic from '../../components/LoadingBasic';

const Sites = () => {
  const navigate = useNavigate();
  const {
    siteList,
    loading,
    setConfirmationModal,
    confirmationModal,
    handleDeleteSite,
  } = useSiteList();

  const deleteConfirmation = (id) => {
    return siteList.filter((site) => site._id !== id);
  };

  const columns = useSitesColumns(deleteConfirmation);

  const handleAddSite = () => {
    navigate(`/sites/create`);
  };

  return (
    <>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ShieldMain title={'Site list'} />
          {loading ? (
            <MDBox height="90vh" pt="25vh">
              <LoadingBasic />
            </MDBox>
          ) : (
            <>
              <MDBox
                px={4}
                pt={2.5}
                pb={1.5}
                lineHeight={1}
                display="flex"
                alignItems="center"
                sx={{ justifyContent: { xs: 'center', sm: 'flex-end' } }}
                onClick={handleAddSite}
              >
                <MDButton variant="contained" color="info">
                  Add site
                </MDButton>
              </MDBox>

              <DataTable
                tableData={siteList}
                columnsArray={columns}
                canSearch
              />
              <ConfirmationModal
                modalShow={confirmationModal}
                setModalShow={setConfirmationModal}
                handleAction={handleDeleteSite}
              />
            </>
          )}
        </Card>
      </MDBox>
      <Footer />
    </>
  );
};

const SitesWrapper = () => {
  return (
    <DashboardLayout>
      <SiteListProvider>
        <Sites />
      </SiteListProvider>
    </DashboardLayout>
  );
};

export default SitesWrapper;
