// react-routers components
import { Link } from 'react-router-dom';

// prop-types is library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Tooltip from '@mui/material/Tooltip';
import {
  CardActions,
  CardContent,
  IconButton,
  Modal,
  Stack,
} from '@mui/material';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React base styles
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../components/DashboardNavbar';
import Footer from '../../../components/Footer';
import QACard from './QACard';

import faqsListData from './faqdata/faqdata';
import AddBoxIcon from '@mui/icons-material/AddBox';
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import pxToRem from '../../../assets/theme/functions/pxToRem';
import MDTypography from '../../../components/MDTypography';
import MDButton from '../../../components/MDButton';
import FormField from '../../../layouts/pages/account/components/FormField';

//modal style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //width: 400,
  //bgcolor: 'background.paper',
  //border: '2px solid #000',
  //boxShadow: 24,
  //p: 4,
};

function FAQs() {
  const [faqs, setFaqs] = useState(faqsListData);
  const [open, setOpen] = useState(false);
  const [faq, setFaq] = useState({ id: null, question: '', answer: '' });
  // const [question, setQuestion] = useState('');
  // const [answer, setAnswer] = useState('');

  //modal handlers
  const handleEdit = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const removeFaq = (id) => {
    setFaqs((faqs) => {
      return faqs.filter((faq) => faq.id !== id);
    });
  };

  const updateFaq = (faq) => {
    const { question, answer } = faq;
    const updatedFaqs = faqs.map((f) =>
      f.id === faq.id ? { ...f, question, answer } : f
    );
    setFaqs(updatedFaqs);
  };

  const saveFaq = () => {
    const nextId = faqs[faqs.length - 1].id + 1;
    const newFaq = { ...faq, id: nextId };
    setFaqs((prevFaqs) => {
      return [...prevFaqs, newFaq];
    });
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={5} mb={10} maxWidth={950} mx="auto">
          <Card sx={{ pb: pxToRem(25) }}>
            <MDBox
              mt={-3}
              mx={3}
              bgColor="info"
              borderRadius="lg"
              minHeight="80px"
              textAlign="center"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MDTypography variant="h3" color="light" fontWeight="regular">
                Settings - FAQs
              </MDTypography>
            </MDBox>
            <MDBox
              px={3}
              py={1}
              lineHeight={1}
              display="flex"
              alignItems="center"
            >
              <MDBox
                p={2}
                lineHeight={1}
                display="flex"
                flex="auto"
                flexDirection="column"
              >
                <MDTypography variant="h5" fontWeight="medium">
                  FAQs
                </MDTypography>
              </MDBox>
              <MDButton
                variant="contained"
                color="info"
                onClick={() => {
                  handleEdit();
                }}
              >
                Add FAQ
              </MDButton>
            </MDBox>
            <MDBox sx={{ px: 3 }}>
              <Stack spacing={2}>
                {faqs.map((faq) => {
                  return (
                    <QACard
                      key={faq.id}
                      faq={faq}
                      handleDelete={removeFaq}
                      updateFaq={updateFaq}
                    />
                  );
                })}
              </Stack>
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, maxWidth: 550, maxHeight: 200 }}>
          <CardContent>
            <FormField
              label="Question"
              type="text"
              value={faq.question}
              onChange={(e) =>
                setFaq((prevFaq) => {
                  return { ...prevFaq, question: e.target.value };
                })
              }
            />
            <FormField
              label="Answer"
              type="text"
              value={faq.answer}
              onChange={(e) =>
                setFaq((prevFaq) => {
                  return { ...prevFaq, answer: e.target.value };
                })
              }
            />
          </CardContent>
          <CardActions>
            <MDButton
              onClick={() => {
                saveFaq();
                setFaq((prevFaq) => {
                  return { ...prevFaq, question: '', answer: '' };
                });
                handleClose();
              }}
            >
              Save
            </MDButton>
            <MDButton onClick={handleClose}>Cancel</MDButton>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
}

export default FAQs;
