/* eslint-disable no-unused-vars */
/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from 'yup';
import checkout from './form';

const {
  formField: { email, password },
} = checkout;

export default Yup.object().shape({
  [email.name]: Yup.string()
    .required('Required')
    .email('Email must be a valid email!'),
  [password.name]: Yup.string().required('Required'),
});
