import MDBadge from '../../../components/MDBadge';
import MDButton from '../../../components/MDButton';
import VideocamIcon from '@mui/icons-material/Videocam';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import MDTypography from '../../../components/MDTypography';
import { Stack } from '@mui/material';
import { useCampaignDelete } from '../../../utils/hooks/use-allCampaigns';
import { toast } from 'react-toastify';

const useCampaignsColumns = () => {
  const { deleteCampaign } = useCampaignDelete();
  const navigate = useNavigate();

  const handleDeleteCampaign = (campaignId) => {
    toast.promise(
      deleteCampaign({
        variables: {
          removeCampaignId: campaignId,
        },
      }),
      {
        pending: 'Pending...',
        success: {
          render({ data }) {
            return data.data.removeCampaign.message;
          },
          icon: '🟢',
        },
        error: {
          render({ data }) {
            return data.message;
          },
        },
      }
    );
  };

  return [
    {
      Header: 'Date',
      accessor: (campaign) => {
        const localUpdatedDate = new Date(campaign?.updatedAt);
        return (
          <>
            <MDTypography variant="caption">
              {localUpdatedDate.toLocaleDateString()}
            </MDTypography>
            <br />
            <MDTypography variant="caption">
              {localUpdatedDate.toLocaleTimeString()}
            </MDTypography>
          </>
        );
      },
    },
    {
      Header: 'Start',
      accessor: (campaign) => {
        const localStartDate = new Date(campaign?.duration?.startDate);
        return (
          <>
            <MDTypography variant="caption">
              {localStartDate.toLocaleDateString()}
            </MDTypography>
            <br />
            <MDTypography variant="caption">
              {localStartDate.toLocaleTimeString()}
            </MDTypography>
          </>
        );
      },
    },
    {
      Header: 'End',
      accessor: (campaign) => {
        const localEndDate = new Date(campaign?.duration?.endDate);
        return (
          <>
            <MDTypography variant="caption">
              {localEndDate.toLocaleDateString()}
            </MDTypography>
            <br />
            <MDTypography variant="caption">
              {localEndDate.toLocaleTimeString()}
            </MDTypography>
          </>
        );
      },
    },
    {
      Header: 'Sites count',
      accessor: ({ banners }) =>
        new Set(banners.map((banner) => banner.siteId)).size || 0,
    },
    {
      Header: 'Add type',
      accessor: 'category',
      Cell: ({ row }) => {
        return (
          <Stack spacing={0.25}>
            {row.original.category.map((item) => (
              <MDBadge key={item} badgeContent={item} container />
            ))}
          </Stack>
        );
      },
    },
    {
      Header: 'Channel',
      accessor: 'traffic',
      Cell: ({ row }) => {
        return (
          <>
            {row.original.preferredChannels.map((item) => (
              <Fragment key={item}>
                {item === 'livecam' && (
                  <VideocamIcon fontSize="medium" color="info" />
                )}
                {item === 'tube' && (
                  <OndemandVideoIcon fontSize="medium" color="info" />
                )}
              </Fragment>
            ))}
          </>
        );
      },
    },
    {
      Header: 'User',
      accessor: (campaign) => {
        return (
          <MDTypography variant="caption">{campaign.userName}</MDTypography>
        );
      },
    },
    {
      Header: 'Tokens',
      accessor: (campaigns) => (
        <MDTypography variant="subtitle2" fontWeight="regular">
          {campaigns.tokens}
        </MDTypography>
      ),
    },
    {
      Header: '',
      accessor: 'actions',
      isSorted: false,
      Cell: ({ row }) => {
        return (
          <>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              style={{ marginRight: 5 }}
              onClick={() => navigate(`/campaigns/edit/${row.original._id}`)}
            >
              Edit
            </MDButton>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              style={{ marginRight: 5 }}
              onClick={() => {
                handleDeleteCampaign(row.original._id);
              }}
            >
              Delete
            </MDButton>
          </>
        );
      },
    },
  ];
};

export default useCampaignsColumns;
