/**
 * External dependencies
 */
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { ALL_COUNTRIES } from '../queries/allCountries';

export default function useAllCountries() {
  const { data, loading, error } = useQuery(ALL_COUNTRIES);

  return {
    data,
    loading,
    error,
  };
}
