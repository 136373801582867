/**
 * External dependencies
 */
import { useContext } from 'react';

/**
 * Internal dependencies
 */
import AuthContext from './context';

const useAuthContext = () => {
  return useContext(AuthContext);
};

export default useAuthContext;
