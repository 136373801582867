/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  DialogContent,
  Input,
  Modal,
  Slider,
  Stack,
} from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import React, { useEffect, useState } from 'react';
import MDButton from 'components/MDButton';
import getCroppedImg from 'utils/cropImage';
import { v4 as uuidv4 } from 'uuid';
import uploadFile from 'utils/firebase/uploadFile';
import Cropper from 'react-easy-crop';
import { useSite } from '../useSiteContext';

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};

const ModalAddSite = ({
  modalShow,
  setModalShow,
  showType,
  imageURL,
  setImageURL,
  redirectLink,
  setRedirectLink,
  type,
}) => {
  const { site, setBanner } = useSite();
  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    setPhotoURL(imageURL);
  }, [imageURL]);

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setPhotoURL(URL.createObjectURL(file));
    }
  };

  const resetCrop = () => {
    setZoom(1);
    setRotation(0);
  };

  const cropImage = async () => {
    const { file } = await getCroppedImg(photoURL, croppedAreaPixels, rotation);

    if (file) {
      const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
      const url = await uploadFile(file, `sites/${site._id}/${imageName}`);
      setImageURL(url);
      setBanner(type, {
        defaultImageUrl: url,
        defaultRedirectUrl: redirectLink,
      });
    }
  };

  const rotateLeft = () => {
    if (rotation >= -170) {
      setRotation((prevState) => prevState - 10);
      return;
    }
    setRotation(-180);
  };

  const rotateRight = () => {
    if (rotation <= 170) {
      setRotation((prevState) => prevState + 10);
      return;
    }
    setRotation(180);
  };

  const zoomOut = () => {
    if (zoom === 1) {
      return;
    }
    setZoom((prevState) => prevState - 0.1);
  };

  const zoomIn = () => {
    if (zoom === 3) {
      return;
    }
    setZoom((prevState) => prevState + 0.1);
  };

  return (
    <Modal
      open={modalShow}
      onClose={() => setModalShow(false)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MDBox
        bgColor="white"
        width="100%"
        maxWidth="507px"
        borderRadius="12px"
        sx={{
          boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.25)',
          padding: '16px 8px',
          overflow: 'scroll',
        }}
      >
        <MDTypography
          sx={{
            fontSize: '16px',
            lineHeight: '16px',
            color: '#7B809A',
            fontWeight: 'bold',
          }}
        >
          Edit image - {type}
        </MDTypography>
        <MDBox sx={{ marginTop: '12px' }}>
          {type !== 'popup' ? (
            <>
              {photoURL ===
              'https://i0.wp.com/shahpourpouyan.com/wp-content/uploads/2018/10/orionthemes-placeholder-image-1.png?fit=869%2C580&ssl=1' ? (
                <MDBox
                  bgColor="grey"
                  sx={{
                    width: '100%',
                    minHeight: '250px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '12px',
                  }}
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      sx={{
                        visibility: 'hidden',
                        position: 'absolute',
                        width: 0,
                        height: 0,
                      }}
                      onChange={handleImage}
                    />
                    <Button
                      variant="contained"
                      component="span"
                      sx={{ color: 'white !important' }}
                    >
                      Upload
                    </Button>
                  </label>
                </MDBox>
              ) : (
                <DialogContent
                  dividers
                  sx={{
                    background: '#333',
                    position: 'relative',
                    height: showType === 'landscape' ? 200 : 300,
                    width: 'auto',
                  }}
                >
                  <Cropper
                    image={photoURL}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={showType === 'landscape' ? 7.5 / 1 : 2 / 4}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                    onCropChange={setCrop}
                    onCropComplete={cropComplete}
                    // cropSize={{
                    //   width: showType === 'landscape' ? 600 : 250,
                    //   height: 200,
                    // }}
                  />
                </DialogContent>
              )}
              <MDBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}
                marginTop="10px"
              >
                <MDTypography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    color: '#7B809A',
                    cursor: 'pointer',
                  }}
                  fontWeight="bold"
                  onClick={() => {
                    setFile(null);
                    setPhotoURL(
                      'https://i0.wp.com/shahpourpouyan.com/wp-content/uploads/2018/10/orionthemes-placeholder-image-1.png?fit=869%2C580&ssl=1'
                    );
                    resetCrop();
                  }}
                >
                  Reset
                </MDTypography>
              </MDBox>
              <MDBox
                sx={{ margin: '0 auto', marginTop: '20px', maxWidth: '300px' }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1 }}
                  alignItems="center"
                >
                  <RemoveIcon
                    fontSize="medium"
                    cursor="pointer"
                    onClick={zoomOut}
                  />
                  <Slider
                    valueLabelDisplay="auto"
                    valueLabelFormat={zoomPercent}
                    min={1}
                    max={3}
                    step={0.1}
                    value={zoom}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                  <AddIcon
                    fontSize="medium"
                    cursor="pointer"
                    onClick={zoomIn}
                  />
                </Stack>

                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1 }}
                  alignItems="center"
                >
                  <RotateLeftIcon
                    fontSize="medium"
                    cursor="pointer"
                    onClick={rotateLeft}
                  />
                  <Slider
                    aria-label="Rotate"
                    min={-180}
                    max={180}
                    value={rotation}
                    onChange={(e, rotation) => setRotation(rotation)}
                  />
                  <RotateRightIcon
                    fontSize="medium"
                    cursor="pointer"
                    onClick={rotateRight}
                  />
                </Stack>
              </MDBox>
            </>
          ) : null}
          <MDBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              margin: '57px 22px 70px 22px',
              borderTop: type !== 'popup' && '1px solid #C7CCD0',
              paddingTop: '20px',
            }}
          >
            <MDTypography
              sx={{
                fontSize: '16px',
                lineHeight: '16px',
                color: '#7B809A',
              }}
              fontWeight="bold"
            >
              Set Link:
            </MDTypography>
            <Input
              id="my-input"
              aria-describedby="my-helper-text"
              disableUnderline={true}
              placeholder="http://www.ceva.com/something"
              value={redirectLink ?? ''}
              sx={{ marginLeft: '10px', flex: '1' }}
              onChange={(e) => {
                setRedirectLink(e.target.value);
                setBanner(type, {
                  defaultImageUrl: imageURL,
                  defaultRedirectUrl: e.target.value,
                });
              }}
            />
          </MDBox>
          <MDBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <MDButton color="dark" onClick={() => setModalShow(false)}>
              Cancel
            </MDButton>
            <MDButton
              color="dark"
              disabled={
                ((!photoURL || !redirectLink) && type !== 'popup') ||
                (type === 'popup' && !redirectLink)
              }
              onClick={() => {
                if (type !== 'popup') {
                  cropImage();
                } else {
                  setBanner(type, {
                    defaultRedirectUrl: redirectLink,
                  });
                }
                setModalShow(false);
              }}
            >
              Accept changes
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

export default ModalAddSite;
