/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_COUNTRIES = gql`
  query AllCountriesQuery {
    allCountries {
      _id
      label
      code
    }
  }
`;
