/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ADD_PARTNER = gql`
  mutation AddPartner($input: AddPartnerInput) {
    addPartner(input: $input) {
      _id
      name
      logo
      homepage
    }
  }
`;
