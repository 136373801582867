/* eslint-disable react-hooks/exhaustive-deps */
import { Icon, IconButton, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ModalAddSite from './ModalAddSite';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { useSite } from '../useSiteContext';
import ModalCopyScript from 'components/ModalCopyScript';
import { useParams } from 'react-router-dom';

const BannerTemplate = ({ type }) => {
  const { bannersSet } = useSite();
  const { id } = useParams();
  const isMobile = useMediaQuery('(max-width:769px)');
  const [modalShow, setModalShow] = useState(false);
  const [modalShowCopyScript, setModalShowCopyScript] = useState(false);
  const landscape = ['header', 'footer'].includes(type) && !isMobile;
  const showType = landscape ? 'landscape' : 'portrait';

  const [imageURL, setImageURL] = useState(
    'https://i0.wp.com/shahpourpouyan.com/wp-content/uploads/2018/10/orionthemes-placeholder-image-1.png?fit=869%2C580&ssl=1'
  );
  const [redirectLink, setRedirectLink] = useState();

  useEffect(() => {
    if (bannersSet?.length > 0) {
      const [bannerObj] = bannersSet.filter((banner) => banner.type === type);
      if (bannerObj) {
        const { defaultImageUrl, defaultRedirectUrl } = bannerObj;
        setImageURL(() => defaultImageUrl);
        setRedirectLink(() => defaultRedirectUrl);
      }
    }
  }, [bannersSet]);

  return (
    <Card sx={{ boxShadow: 4 }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <CardHeader
            title={type}
            titleTypographyProps={{ fontSize: '15px', color: 'secondary' }}
          />
        </Grid>
        <Grid item>
          <Tooltip title={'copy code'}>
            <IconButton
              color="secondary"
              onClick={() => setModalShowCopyScript(true)}
            >
              <Icon fontSize="medium">copy</Icon>
            </IconButton>
          </Tooltip>
          <IconButton color="secondary" onClick={() => setModalShow(true)}>
            <Icon fontSize="medium">edit</Icon>
          </IconButton>
        </Grid>
      </Grid>
      <CardMedia
        component="img"
        src={
          type === 'popup'
            ? 'https://i0.wp.com/shahpourpouyan.com/wp-content/uploads/2018/10/orionthemes-placeholder-image-1.png?fit=869%2C580&ssl=1'
            : imageURL
        }
        alt="img"
        sx={{
          height: { xs: 240, md: landscape ? 120 : 240 },
          mb: 2,
          mt: -0.25,
        }}
      />
      <ModalCopyScript
        modalShow={modalShowCopyScript}
        setModalShow={setModalShowCopyScript}
        type={type}
        id={id}
      />
      <ModalAddSite
        modalShow={modalShow}
        setModalShow={setModalShow}
        showType={showType}
        imageURL={imageURL}
        setImageURL={setImageURL}
        type={type}
        redirectLink={redirectLink}
        setRedirectLink={setRedirectLink}
      />
    </Card>
  );
};

export default BannerTemplate;
