/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ADD_VOUCHER = gql`
  mutation AddVoucher($value: Int) {
    addVoucher(value: $value) {
      _id
      code
      value
      userId
      redeemedAt
      createdAt
      userName
    }
  }
`;
