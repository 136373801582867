/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useEffect, useState } from 'react';

// @mui material components
import Container from '@mui/material/Container';

// Material Dashboard 2 PRO React example components
import PageLayout from 'examples/LayoutContainers/PageLayout';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';

// Pricing page components
import Header from 'layouts/pages/pricing-page/components/Header';
import Footer from '../../../components/Footer';
import PricingCards from '../components/PricingCards/index';
import Card from '@mui/material/Card';
import DashboardNavbar from '../../../components/DashboardNavbar';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import useAllVouchers from '../../../utils/hooks/use-allVouchers';
import breakpoints from '../../../assets/theme/base/breakpoints';
import useAddVoucher from '../../../utils/hooks/useAddVoucher';
import { toast } from 'react-toastify';
import { Stack } from '@mui/material';
import MDButton from '../../../components/MDButton';
import ShieldMain from '../../../components/ShieldMain/ShieldMain';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LoadingBasic from '../../../components/LoadingBasic';
import DataTable from '../../../blocks/Tables/DataTable';
import columns from '../Bundles/columns';
import { bundlesDummyData } from './bundlesDummyData';

function Bundles() {
  // const { data, loading, error } = useAllVouchers();
  // const allVouchers = data?.allVouchers.sort((a, b) => {
  //   return new Date(b.createdAt) - new Date(a.createdAt);
  // });

  const loading = false;
  const allBundles = [...bundlesDummyData];

  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  function tableData(tabValue) {
    switch (tabValue) {
      // case 1:
      //   return allBundles.filter((row) => row.redeemedAt);
      // case 2:
      //   return allBundles.filter((row) => !row.redeemedAt);
      case 3:
        return allBundles.filter((bundle) => bundle.category === 'MODEL');
      case 4:
        return allBundles.filter((bundle) => bundle.category === 'STUDIO');

      default:
        return allBundles;
    }
  }

  const [open, setOpen] = useState(false);
  const [tokens, setTokens] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTokens('');
    setOpen(false);
  };

  const handleChange = (newValue) => {
    if (!newValue) setTokens(newValue);
    const updatedValue = parseInt(newValue);
    if (updatedValue) {
      setTokens(updatedValue);
    }
  };

  const { addVoucher, loading: loadingAdded } = useAddVoucher();

  const handleAdd = () => {
    toast('Temporarily unavailable ...');
  };

  const [prices, setPrices] = useState(['59', '89', '99']);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={3} maxWidth={950} mx="auto">
        <Card>
          <ShieldMain title={'Bundles'} />
          <Stack
            mx={4}
            my={3}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <MDButton variant="contained" color="info" onClick={handleAdd}>
              Add bundle
            </MDButton>
          </Stack>
          <MDBox
            pl={5}
            pr={5}
            lineHeight={2}
            display="flex"
            alignItems="center"
          >
            <Grid item xs={12} sm={8} lg={8}>
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
              >
                <Tab label="All" />
                <Tab label="Expired" />
                <Tab label="Active" />
                <Tab label="Models" />
                <Tab label="Studios" />
              </Tabs>
            </Grid>
          </MDBox>
          {loading ? (
            <LoadingBasic />
          ) : (
            <DataTable
              isSorted={false}
              columnsArray={columns}
              tableData={tableData(tabValue)}
              canSearch
              pagination={{ variant: 'gradient', color: 'success' }}
              noEndBorder
            />
          )}

          {/*<PricingCards prices={prices} />*/}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Bundles;
