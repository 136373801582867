/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';

// Soft UI Context Provider
import { MaterialUIControllerProvider } from 'context';
import { AuthProvider } from './utils/authentication/provider';
import ApolloClientProvider from './utils/apollo-client/apollo-client-provider';

ReactDOM.render(
  <AuthProvider>
    <ApolloClientProvider>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </ApolloClientProvider>
  </AuthProvider>,
  document.getElementById('root')
);
