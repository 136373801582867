/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDButton from 'components/MDButton';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'blocks/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../components/DashboardNavbar';
import Footer from 'blocks/Footer';

// components
import UsersDetailCard from './components/UsersDetailCard';
import UsersDetail from './components/UsersDetail';
import UserButtons from './components/UserButtons';
import MDBox from '../../../components/MDBox';
import React, { useEffect, useState } from 'react';
import ShieldMain from '../../../components/ShieldMain/ShieldMain';
import MDTypography from '../../../components/MDTypography';
import LoadingBasic from '../../../components/LoadingBasic';
import UserTransactions from './components/UserTransactions';
import useGetUserTransactions from '../../../utils/hooks/use-getUserTransactions';
import { useProfileById } from '../../../utils/queries/queries';

const EditUser = () => {
  const [totalBalance, setTotalBalance] = useState(0);
  const { id: userId } = useParams();

  const { data: userData, loading: userLoading } = useProfileById(userId);
  const currentUser = userData?.getUserById ?? {};

  const {
    data: { getUserTransactions: userTransactions = [] } = {},
    loading: transactionsLoading,
  } = useGetUserTransactions(userId);

  useEffect(() => {
    const addedFunds = userTransactions.reduce((funds, transaction) => {
      if (
        ['redeem', 'added'].some((element) =>
          transaction.label.includes(element)
        )
      ) {
        funds += transaction.amount;
      }
      return funds;
    }, 0);
    setTotalBalance(addedFunds);
  }, [userTransactions]);

  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mt={{ xs: 7, sm: 15 }}
        maxWidth={950}
        mx="auto"
        minHeight={{ lg: '80vh' }}
      >
        {userLoading || transactionsLoading ? (
          <LoadingBasic />
        ) : (
          <Card>
            <ShieldMain
              title={currentUser.profile.name ?? ''}
              avatar={currentUser.profile.avatar}
            />
            <Grid mt={3} px={3} container spacing={2}>
              {/*////// CARDS AREA //////*/}
              <Grid item container mb={2} xs={12} justifyContent="space-around">
                <Grid item xs={10} sm={5} md={5} lg={2.8} xl={2.2}>
                  <UsersDetailCard
                    header="Username"
                    content={
                      <MDTypography
                        variant="body2"
                        color="secondary"
                        sx={{ mt: 1.25 }}
                      >
                        {currentUser.username ?? ''}
                      </MDTypography>
                    }
                  />
                </Grid>
                <Grid item xs={10} sm={5} md={5} lg={2.8} xl={2.2}>
                  <UsersDetailCard
                    header="Current Balance"
                    content={
                      <MDTypography
                        variant="body2"
                        color="secondary"
                        sx={{ mt: 1.25 }}
                      >
                        {`${currentUser.tokens || 0} tokens`}
                      </MDTypography>
                    }
                  />
                </Grid>
                <Grid item xs={10} sm={5} md={5} lg={2.8} xl={2.2}>
                  <UsersDetailCard
                    header="Total Balance"
                    content={
                      <MDTypography
                        variant="body2"
                        color="secondary"
                        sx={{ mt: 1.25 }}
                      >
                        {`${totalBalance} tokens`}
                      </MDTypography>
                    }
                  />
                </Grid>
                <Grid item xs={10} sm={5} md={5} lg={2.8} xl={2.2}>
                  <UsersDetailCard
                    header="Campaigns"
                    content={
                      <MDTypography
                        variant="body2"
                        color="secondary"
                        sx={{ mt: 1.25 }}
                      >
                        {currentUser.nrOfCampaigns ?? 0}
                      </MDTypography>
                    }
                  />
                </Grid>
              </Grid>
              {/*////// USER DETAILS AREA //////*/}
              <UsersDetail currentUser={currentUser} />
              {/*////// USER BUTTONS AREA //////*/}
              <UserButtons currentUser={currentUser} />
              {/*////// USER TRANSACTIONS AREA //////*/}
              <UserTransactions userId={currentUser._id} />
              <Grid pb={3} item xs={11} md={11} lg={11}>
                <FormField label="Internal Notes" />
              </Grid>
            </Grid>
            <Grid pb={2} container width="98%" justifyContent="space-between">
              <MDButton
                sx={{ marginLeft: '25px' }}
                size="medium"
                variant="contained"
                color="light"
                onClick={() => navigate('/users')}
              >
                Cancel
              </MDButton>
              <MDButton size="medium" variant="contained" color="dark">
                Save
              </MDButton>
            </Grid>
          </Card>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditUser;
