// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Settings page components
import FormField from '../../components/FormField';

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'blocks/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../components/DashboardNavbar';
import Footer from 'blocks/Footer';

//Data
import MDButton from 'components/MDButton';
import ShieldMain from '../../components/ShieldMain/ShieldMain';

import { Form, Formik } from 'formik';
import form from './schemas/form';
import emptyValues from './schemas/initialValues';
import MDInput from '../../components/MDInput';
import { SiteProvider, useSite } from './useSiteContext';
import { useNavigate } from 'react-router-dom';
import { siteFormValidation } from './siteFormValidation';
import { categories } from '../../utils/data/categories';

const AddSite = () => {
  const navigate = useNavigate();
  const { site, handleSubmit, subcategories } = useSite();
  let initialValues;

  if (site) {
    initialValues = { ...site };
  } else {
    initialValues = { ...emptyValues };
  }
  const { formId, formField } = form;
  const { name, domain, category, subCategory, serviceType, blockedTraffic } =
    formField;

  return (
    <>
      <DashboardNavbar />
      <MDBox
        mt={{ xs: 7, sm: 10 }}
        mb={4}
        maxWidth={950}
        mx="auto"
        minHeight={{ lg: '80vh' }}
      >
        <Card id="basic-info" sx={{ overflow: 'visible' }}>
          <ShieldMain title="Site details" />
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={siteFormValidation}
          >
            {({ values, dirty, isValid, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <MDBox my={4} sx={{ px: { xs: 2, sm: 8 } }}>
                  <Grid container rowSpacing={1}>
                    <Grid item container>
                      <Grid item xs={3}>
                        <MDTypography
                          variant="h6"
                          color="secondary"
                          fontWeight="regular"
                        >{`${name.label}`}</MDTypography>
                      </Grid>
                      <Grid item xs={7}>
                        <FormField
                          type={name.type}
                          label={''}
                          name={name.name}
                          value={values.name}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={3}>
                        <MDTypography
                          variant="h6"
                          color="secondary"
                          fontWeight="regular"
                        >{`${domain.label}`}</MDTypography>
                      </Grid>
                      <Grid item xs={7}>
                        <FormField
                          type={domain.type}
                          label={''}
                          name={domain.name}
                          value={values.domain}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={3}>
                        <MDTypography
                          variant="h6"
                          color="secondary"
                          fontWeight="regular"
                        >{`${category.label}`}</MDTypography>
                      </Grid>
                      <Grid item xs={7}>
                        <Autocomplete
                          multiple
                          options={categories}
                          value={values.category}
                          onChange={(e, newValue) => {
                            setFieldValue('category', newValue);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params} variant="standard" label="" />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={3}>
                        <MDTypography
                          variant="h6"
                          color="secondary"
                          fontWeight="regular"
                        >{`${subCategory.label}`}</MDTypography>
                      </Grid>
                      <Grid item xs={7}>
                        <Autocomplete
                          multiple
                          options={subcategories.sort()}
                          value={values.subCategory}
                          onChange={(e, newValue) => {
                            setFieldValue('subCategory', newValue);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params} variant="standard" label="" />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={3}>
                        <MDTypography
                          variant="h6"
                          color="secondary"
                          fontWeight="regular"
                        >{`${serviceType.label}`}</MDTypography>
                      </Grid>
                      <Grid item xs={7}>
                        <Autocomplete
                          options={['livecam', 'tube']}
                          value={values.serviceType}
                          onChange={(e, newValue) => {
                            setFieldValue('serviceType', newValue);
                          }}
                          renderInput={(params) => (
                            <MDInput {...params} variant="standard" label="" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mt={1} mx={4}>
                  <Grid
                    py={3}
                    container
                    justifyContent="space-between"
                    rowSpacing={1}
                  >
                    <Grid item xs={12} md="auto">
                      <MDButton
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                        onClick={() => navigate('/sites')}
                        variant="contained"
                        color="dark"
                        disableElevation
                        size="medium"
                      >
                        Cancel
                      </MDButton>
                    </Grid>

                    <Grid item xs={12} md="auto">
                      <MDButton
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                        variant="contained"
                        color="dark"
                        disableElevation
                        size="medium"
                        type="submit"
                        disabled={!(dirty && isValid)}
                      >
                        Save
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Card>
      </MDBox>
      <Footer />
    </>
  );
};

const AddSiteWrapper = () => {
  return (
    <SiteProvider>
      <DashboardLayout>
        <AddSite />
      </DashboardLayout>
    </SiteProvider>
  );
};

export default AddSiteWrapper;
