/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const GET_USER = gql`
  query UserQuery {
    user {
      _id
      blockCountries
      email
      nrOfCampaigns
      profile {
        avatar
        birthDate
        gender
        name
        registrationWizard
      }
      socialProfile {
        instagram
        onlyfans
        snapchat
        twitter
      }
      status {
        onlineAdminDash
        onlineUserDash
      }
      tokens
      updatedAt
      username
      workingSites
    }
  }
`;
