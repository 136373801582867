import React from 'react';
import useAuthContext from '../authentication/use-AuthContext';
import { Navigate } from 'react-router-dom';

function RequireAuth({ children }) {
  let { isLoggedIn } = useAuthContext();

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default RequireAuth;
