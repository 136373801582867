import MDButton from '../../../components/MDButton';
import MDTypography from '../../../components/MDTypography';
import React from 'react';
import { toast } from 'react-toastify';

const handleDetails = () => {
  toast('Temporarily unavailable ...');
};

const columns = [
  {
    Header: 'created on',
    accessor: (bundle) => {
      const createDate = new Date(bundle.createdAt);
      return (
        <>
          <MDTypography variant="caption">
            {createDate.toLocaleDateString()}
          </MDTypography>
          <br />
          <MDTypography variant="caption">
            {createDate.toLocaleTimeString()}
          </MDTypography>
        </>
      );
    },
  },
  {
    Header: 'category',
    accessor: (bundle) => {
      return <MDTypography variant="caption">{bundle.category}</MDTypography>;
    },
  },
  {
    Header: 'label',
    accessor: (bundle) => {
      return <MDTypography variant="caption">{bundle.label}</MDTypography>;
    },
  },
  {
    Header: 'visible',
    accessor: (bundle) => {
      return bundle.visible ? 'yes' : 'no';
    },
  },
  { Header: 'price', accessor: 'price' },
  { Header: 'rate', accessor: 'rate' },
  { Header: 'bonus', accessor: 'bonus' },
  {
    Header: 'start',
    accessor: (bundle) => {
      const startDate = new Date(bundle.activeFrom);
      return (
        <>
          <MDTypography variant="caption">
            {startDate.toLocaleDateString()}
          </MDTypography>
          <br />
          <MDTypography variant="caption">
            {startDate.toLocaleTimeString()}
          </MDTypography>
        </>
      );
    },
  },
  {
    Header: 'end',
    accessor: (bundle) => {
      return bundle.activeUntil ? (
        <>
          <MDTypography variant="caption">
            {new Date(bundle.activeUntil).toLocaleDateString()}
          </MDTypography>
          <br />
          <MDTypography variant="caption">
            {new Date(bundle.activeUntil).toLocaleTimeString()}
          </MDTypography>
        </>
      ) : (
        <MDTypography variant="caption">Perpetual</MDTypography>
      );
    },
  },

  {
    Header: 'actions',
    accessor: 'actions',
    Cell: ({ row }) => {
      return (
        <MDButton
          sx={{ px: '7px' }}
          variant="outlined"
          color="info"
          size="small"
          onClick={handleDetails}
        >
          details
        </MDButton>
      );
    },
  },
];
export default columns;
