/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';

// Base styles
import breakpoints from 'assets/theme/base/breakpoints';

// Components
import MDBox from 'components/MDBox';

// Blocks
import DashboardLayout from 'blocks/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../components/DashboardNavbar';
import Footer from 'components/Footer';
import DataTable from '../../components/DataTable';

// Data
import ShieldMain from '../../components/ShieldMain/ShieldMain';
import useCampaignsColumns from './hooks/useCampaignsColumns';
import LoadingBasic from '../../components/LoadingBasic';
import { useAllCampaigns } from '../../utils/hooks/use-allCampaigns';

const CampaignList = () => {
  const { data, loading, error } = useAllCampaigns();

  const columns = useCampaignsColumns();

  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  function tableData() {
    const campaignList = data.allCampaigns.edges.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });

    switch (tabValue) {
      case 1:
        return campaignList.filter(
          (row) => new Date(row?.duration?.endDate ?? '') >= new Date()
        );
      case 2:
        return campaignList.filter(
          (row) => new Date(row?.duration?.endDate ?? '') < new Date()
        );
      default:
        return campaignList;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ShieldMain title={'Campaigns'} />
          <MDBox
            px={4}
            pt={2.5}
            pb={1.5}
            lineHeight={1}
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: { xs: 'center', sm: 'flex-end' },
              minHeight: '70px', //not required when add button is visible
            }}
          ></MDBox>
          <MDBox
            pl={5}
            pr={5}
            lineHeight={2}
            display="flex"
            alignItems="center"
          >
            <Grid item xs={12} sm={8} lg={4}>
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
              >
                <Tab label="All" />
                <Tab label="Active" />
                <Tab label="Expired" />
              </Tabs>
            </Grid>
          </MDBox>
          {loading || error ? (
            <MDBox height="90vh" pt="25vh">
              <LoadingBasic />
            </MDBox>
          ) : (
            <DataTable
              columnsArray={columns}
              tableData={tableData()}
              canSearch
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default CampaignList;
