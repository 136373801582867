/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { USER_LOGIN } from '../mutations/user-log-in';

/**
 * Internal dependencies
 */

export default function useUserLogIn() {
  const [loginUser, { loading }] = useMutation(USER_LOGIN);

  return { loginUser, loading };
}
