/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_PARTNERS = gql`
  query AllPartnersQuery {
    allPartners {
      _id
      name
      logo
      homepage
    }
  }
`;
