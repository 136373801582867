import { CardActions, CardContent, CardMedia } from '@mui/material';
import Card from '@mui/material/Card';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

const PlatformCard = ({ partner, handleEdit, handleDelete }) => {
  return (
    <Card sx={{ maxWidth: 300, boxShadow: 3 }}>
      <CardContent sx={{ pb: '5px', pl: '10px' }}>
        <MDTypography variant="subtitle2" fontWeight="medium" color="dark">
          {partner.name}
        </MDTypography>
      </CardContent>
      <CardMedia
        sx={{ m: '0', borderRadius: '0' }}
        component="img"
        image={partner.logo}
        alt="unavailable"
        height="200"
        width="200"
      />
      <CardActions
        sx={{
          p: '0',
          display: 'flex',
          justifyContent: 'space-between',
          minWidth: 100,
        }}
      >
        <MDButton
          disableRipple={true}
          variant="text"
          onClick={() => {
            handleEdit(partner._id);
          }}
        >
          <MDTypography
            variant="button"
            fontWeight="medium"
            color="secondary"
            textTransform="uppercase"
          >
            edit
          </MDTypography>
        </MDButton>
        <MDButton
          disableRipple={true}
          variant="text"
          onClick={() => {
            handleDelete(partner._id);
          }}
        >
          <MDTypography
            variant="button"
            fontWeight={'medium'}
            color="primary"
            textTransform="uppercase"
          >
            delete
          </MDTypography>
        </MDButton>
      </CardActions>
    </Card>
  );
};

export default PlatformCard;
