const sites = [
  { id: 1, name: 'chaturbate', label: 'Chaturbate' },
  { id: 2, name: 'myfreecams', label: 'Myfreecams' },
  { id: 3, name: 'bongacams', label: 'Bongacams' },
  { id: 4, name: 'imlive', label: 'Imlive' },
  { id: 5, name: 'livejasmin', label: 'Livejasmin' },
  { id: 6, name: 'flirt4free', label: 'Flirt4free' },
  { id: 7, name: 'streamate', label: 'Streamate' },
  { id: 8, name: 'xlovecam', label: 'Xlovecam' },
  { id: 9, name: 'stripchat', label: 'Stripchat' },
  { id: 10, name: 'camsoda', label: 'Camsoda' },
  { id: 11, name: 'cams.com', label: 'Cams.com' },
  { id: 12, name: 'onlyfans', label: 'onlyfans' },
  { id: 13, name: 'justfor.fans', label: 'Justfor.fans' },
  { id: 14, name: 'iwantclips', label: 'Iwantclips' },
];
export default sites;
