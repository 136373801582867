/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_USERS = gql`
  query AllUsersQuery($filter: UserFilter) {
    allUsers(filter: $filter) {
      edges {
        _id
        updatedAt
        services {
          password
          google {
            accessToken
            idToken
            id
            email
            name
            given_name
            family_name
            picture
          }
          facebook {
            accessToken
            id
            email
            name
            first_name
            last_name
            link
            gender
            locale
            verified
          }
          apple {
            iss
            aud
            sub
            nonce
            c_hash
            email
            email_verified
            is_private_email
            auth_time
            nonce_supported
          }
        }
        email
        verified_email
        username
        profile {
          registrationWizard
          name
          avatar
          gender
          birthDate
        }
        socialProfile {
          instagram
          twitter
          snapchat
          onlyfans
        }
        status {
          onlineAdminDash
          onlineUserDash
          lastLogin {
            userAgent
            ipAddr
            date
          }
        }
        blockCountries
        workingSites
        nrOfCampaigns
        banners {
          type
          imageUrl
        }
        tokens
        suspended
      }
    }
  }
`;

export const SUSPEND_USER = gql`
  mutation SuspendUser($userId: ID!) {
    suspendUser(id: $userId) {
      node {
        _id
        updatedAt
        services {
          password
          google {
            accessToken
            idToken
            id
            email
            name
            given_name
            family_name
            picture
          }
          facebook {
            accessToken
            id
            email
            name
            first_name
            last_name
            link
            gender
            locale
            verified
          }
          apple {
            iss
            aud
            sub
            nonce
            c_hash
            email
            email_verified
            is_private_email
            auth_time
            nonce_supported
          }
        }
        email
        verified_email
        username
        profile {
          registrationWizard
          name
          avatar
          gender
          birthDate
        }
        socialProfile {
          instagram
          twitter
          snapchat
          onlyfans
        }
        status {
          onlineAdminDash
          onlineUserDash
          lastLogin {
            userAgent
            ipAddr
            date
          }
        }
        blockCountries
        workingSites
        nrOfCampaigns
        banners {
          type
          imageUrl
        }
        tokens
        suspended
      }
      message
    }
  }
`;
