import React, { useContext, createContext, useState } from 'react';
import { useAllSites, useSiteDelete } from 'utils/hooks/use-allSites';
const SiteListContext = createContext({});

export const SiteListProvider = ({ children }) => {
  const { data, loading } = useAllSites();
  const { deleteSite } = useSiteDelete();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [siteId, setSiteId] = useState();

  const deleteConfirmation = (id) => {
    setConfirmationModal(true);
    setSiteId(id);
  };

  const handleDeleteSite = async () => {
    const res = await deleteSite({
      variables: {
        removeSiteId: siteId,
      },
    });

    if (!res.errors) {
      setConfirmationModal(false);
    }
  };

  return (
    <SiteListContext.Provider
      value={{
        siteList: data?.allSites?.edges,
        loading,
        handleDeleteSite,
        confirmationModal,
        setConfirmationModal,
        deleteConfirmation,
      }}
    >
      {children}
    </SiteListContext.Provider>
  );
};
export const useSiteList = () => useContext(SiteListContext);
