/**
 * External dependencies
 */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CAMPAIGN_EDIT,
  CAMPAIGN_CREATE,
  CAMPAIGN_DELETE,
} from 'utils/mutations/allCampaigns';

/**
 * Internal dependencies
 */
import { ALL_CAMPAIGNS, CAMPAIGN_BY_ID } from '../queries/allCampaigns';

export function useAllCampaigns() {
  const { data, loading, error } = useQuery(ALL_CAMPAIGNS, {
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
  };
}

export function useCampaignByIdLazy(id) {
  const [getCampaignByIdQuery, { data, loading, error, refetch }] =
    useLazyQuery(CAMPAIGN_BY_ID, {
      variables: {
        id: id,
      },
    });

  return {
    getCampaignByIdQuery,
    data,
    loading,
    error,
    refetch,
  };
}

export function useCampaignById(id) {
  const { data, loading, error } = useQuery(CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
    },
  });

  return {
    data,
    loading,
    error,
  };
}

export function useCampaignCreate() {
  const [createCampaign, { data, loading, error }] = useMutation(
    CAMPAIGN_CREATE,
    {
      refetchQueries: [{ query: ALL_CAMPAIGNS }],
    }
  );

  return {
    createCampaign,
    data,
    loading,
    error,
  };
}

export function useCampaignEdit() {
  const [editCampaign, { data, loading, error }] = useMutation(CAMPAIGN_EDIT, {
    refetchQueries: [{ query: ALL_CAMPAIGNS }],
  });

  return {
    editCampaign,
    data,
    loading,
    error,
  };
}

export function useCampaignDelete() {
  const [deleteCampaign, { data, loading, error }] = useMutation(
    CAMPAIGN_DELETE,
    {
      refetchQueries: [{ query: ALL_CAMPAIGNS }],
    }
  );

  return {
    deleteCampaign,
    data,
    loading,
    error,
  };
}
