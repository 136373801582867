/* eslint-disable import/no-anonymous-default-export */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const tokens='tokens'
export default {
  columns: [
    { Header: 'online', accessor: 'online',  },
    { Header: 'emailStatus', accessor: 'emailStatus', },
    { Header: 'userId', accessor: 'userId' },
    { Header: 'registered', accessor: 'registered', width: '7%' },
    { Header: 'lastLogin', accessor: 'lastLogin' },
    { Header: 'balance', accessor: 'balance' },
    { Header: 'totalBalance', accessor: 'totalBalance' },
    { Header: 'noOfCampaigns', accessor: 'noOfCampaigns' },
    { Header: 'category', accessor: 'category' },
    { Header: 'actions', accessor: 'actions' },
  ],

  rows: [{
    "online": true,
    "emailStatus": false,
    "userId": "cboagey0",
    "registered": "11/17/2021",
    "lastLogin": "6/26/2021",
    "balance": 1+'' + tokens ,
    "totalBalance":1 +''+ tokens,
    "noOfCampaigns": 1,
    "category": "Large-eared bushbaby",
    "actions": "Peacock, blue"
  }, {
    "online": false,
    "emailStatus": false,
    "userId": "fralestone1",
    "registered": "12/22/2021",
    "lastLogin": "9/12/2021",
    "balance": 2+'' + tokens ,
    "totalBalance": 2+'' + tokens,
    "noOfCampaigns": 2,
    "category": "Booby, blue-footed",
    "actions": "Tailless tenrec"
  }, {
    "online": false,
    "emailStatus": true,
    "userId": "olindsay2",
    "registered": "8/9/2021",
    "lastLogin": "10/19/2021",
    "balance": 3+'' + tokens ,
    "totalBalance": 3+'' + tokens,
    "noOfCampaigns": 3,
    "category": "Bee-eater, white-fronted",
    "actions": "King vulture"
  }, {
    "online": false,
    "emailStatus": false,
    "userId": "sshirtcliffe3",
    "registered": "8/15/2021",
    "lastLogin": "6/6/2021",
    "balance": 4+'' + tokens, 
    "totalBalance": 4+'' + tokens,
    "noOfCampaigns": 4,
    "category": "White-winged dove",
    "actions": "Mule deer"
  }, {
    "online": false,
    "emailStatus": false,
    "userId": "jfewell4",
    "registered": "5/5/2021",
    "lastLogin": "5/4/2021",
    "balance": 5+'' + tokens ,
    "totalBalance": 5+'' + tokens,
    "noOfCampaigns": 5,
    "category": "Bat, asian false vampire",
    "actions": "Eleven-banded armadillo (unidentified)"
  }, {
    "online": true,
    "emailStatus": true,
    "userId": "bcourtman5",
    "registered": "1/7/2022",
    "lastLogin": "10/27/2021",
    "balance": 6+'' + tokens ,
    "totalBalance": 6+'' + tokens,
    "noOfCampaigns": 6,
    "category": "Sable antelope",
    "actions": "White-eye, pale"
  }, {
    "online": true,
    "emailStatus": false,
    "userId": "kslatford6",
    "registered": "12/8/2021",
    "lastLogin": "2/15/2021",
    "balance": 7+'' + tokens ,
    "totalBalance": 7+'' + tokens,
    "noOfCampaigns": 7,
    "category": "Monkey, rhesus",
    "actions": "Marten, american"
  }, {
    "online": false,
    "emailStatus": false,
    "userId": "tnormington7",
    "registered": "4/10/2021",
    "lastLogin": "6/20/2021",
    "balance": 8+'' + tokens ,
    "totalBalance": 8+'' + tokens,
    "noOfCampaigns": 8,
    "category": "Cow, scottish highland",
    "actions": "Knob-nosed goose"
  }, {
    "online": true,
    "emailStatus": true,
    "userId": "jpinnick8",
    "registered": "1/9/2022",
    "lastLogin": "2/15/2021",
    "balance": 9+'' + tokens ,
    "totalBalance": 9+'' + tokens,
    "noOfCampaigns": 9,
    "category": "Dog, african wild",
    "actions": "Owl, madagascar hawk"
  }, {
    "online": true,
    "emailStatus": true,
    "userId": "bmaccaig9",
    "registered": "9/22/2021",
    "lastLogin": "7/24/2021",
    "balance": 10+'' + tokens ,
    "totalBalance": 10+'' + tokens,
    "noOfCampaigns": 10,
    "category": "Jungle cat",
    "actions": "Tern, white-winged"
  }, {
    "online": true,
    "emailStatus": true,
    "userId": "dwofendena",
    "registered": "5/20/2021",
    "lastLogin": "9/14/2021",
    "balance": 11+'' + tokens ,
    "totalBalance": 11+'' + tokens,
    "noOfCampaigns": 11,
    "category": "Little brown bat",
    "actions": "Wallaby, river"
  }, {
    "online": true,
    "emailStatus": false,
    "userId": "nbinneyb",
    "registered": "11/19/2021",
    "lastLogin": "10/23/2021",
    "balance": 12+'' + tokens ,
    "totalBalance": 12+'' + tokens,
    "noOfCampaigns": 12,
    "category": "Turkey vulture",
    "actions": "Tern, royal"
  }, {
    "online": true,
    "emailStatus": false,
    "userId": "patheisc",
    "registered": "7/21/2021",
    "lastLogin": "2/4/2021",
    "balance": 13+'' + tokens ,
    "totalBalance": 13+'' + tokens,
    "noOfCampaigns": 13,
    "category": "Australian brush turkey",
    "actions": "Crane, blue"
  }, {
    "online": true,
    "emailStatus": false,
    "userId": "obruckentd",
    "registered": "9/20/2021",
    "lastLogin": "3/28/2021",
    "balance": 14+'' + tokens ,
    "totalBalance": 14+'' + tokens,
    "noOfCampaigns": 14,
    "category": "Lynx, african",
    "actions": "Fox, north american red"
  }, {
    "online": false,
    "emailStatus": true,
    "userId": "agroundwatore",
    "registered": "10/25/2021",
    "lastLogin": "12/16/2021",
    "balance": 15+'' + tokens ,
    "totalBalance": 15+'' + tokens,
    "noOfCampaigns": 15,
    "category": "Northern fur seal",
    "actions": "Eagle, bateleur"
  }],
};
