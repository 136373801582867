import { Stack } from '@mui/material';
import { ThreeCircles } from 'react-loader-spinner';
import React from 'react';

const LoadingBasic = () => {
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ my: 'auto' }}>
      <ThreeCircles color="#1A73E8" innerCircleColor="#7b809a" width={60} />
    </Stack>
  );
};

export default LoadingBasic;
