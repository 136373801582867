/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { ALL_PARTNERS } from '../queries/allPartners';
import { EDIT_PARTNER } from '../mutations/editPartner';

/**
 * Internal dependencies
 */

export default function useEditPartner() {
  const [editPartner, { loading }] = useMutation(EDIT_PARTNER, {
    refetchQueries: [{ query: ALL_PARTNERS }],
  });

  return { editPartner, loading };
}
