/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { ALL_USERS } from '../queries/allUsers';
import { USER_DELETE } from '../mutations/allUsers';

/**
 * Internal dependencies
 */

export default function useUserDelete() {
  const [removeUser, { data, loading, error }] = useMutation(USER_DELETE, {
    refetchQueries: [{ query: ALL_USERS }],
  });

  return { removeUser, data, loading, error };
}
