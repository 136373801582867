/* eslint-disable react-hooks/exhaustive-deps */
import { TabContext, TabPanel } from '@mui/lab';
import { Icon, IconButton, Modal, Tab, Tabs, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import React, { useState } from 'react';
const ModalCopyScript = ({ modalShow, setModalShow, type, id }) => {
  const [copy, setCopy] = useState(false);
  const [value, setValue] = useState('desktop');

  const script = `<a href="#" id="${type}_link_${value}" align="center">
      <img
        id="${type}_img_${value}"
        alt=""
        src=""
        border="0"
        style="width: ${
          ['header', 'footer'].includes(type) ? '100%' : '90%'
        }; vertical-align: middle"
      />
    </a>
    <script>
      const response_${type}_${value} = fetch(
        "https://exstream.devlabs.ro/api?id=${id}"
      ).then(async (data) => {
        const response_${type}_${value} = await data.json();
        const ${type}_obj_${value} = response_${type}_${value}.banners.find(
          (item) => item.type === "${type}"
        );
        const elemImg_${type}_${value} = document.getElementById("${type}_img_${value}");
        const elemLink_${type}_${value} = document.getElementById("${type}_link_${value}");
        if (new Date(${type}_obj_${value}.bookedUntil) > new Date()) {
          elemImg_${type}_${value}.src = ${type}_obj_${value}.imageUrl;
          elemLink_${type}_${value}.href = ${type}_obj_${value}.redirectUrl;
        } else {
          elemImg_${type}_${value}.src = ${type}_obj_${value}.defaultImageUrl;
          elemLink_${type}_${value}.href = ${type}_obj_${value}.defaultRedirectUrl;
        }
      });
    </script>`;

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(script).then(
        () => {
          setCopy(true);
        },
        (err) => {
          console.log('Failed to copy the text to clipboard.', err);
        }
      );
    } else if (window.clipboardData) {
      window.clipboardData.setData('Text', script);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCopy(false);
  };

  return (
    <Modal
      open={modalShow}
      onClose={() => setModalShow(false)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MDBox
        bgColor="white"
        width="100%"
        maxWidth="80%"
        maxHeight="70%"
        borderRadius="12px"
        sx={{
          boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.25)',
          padding: '16px 8px',
        }}
      >
        <MDTypography
          sx={{
            fontSize: '20px',
            lineHeight: '20px',
            color: '#7B809A',
            fontWeight: 'bold',
          }}
        >
          Generated script
        </MDTypography>
        <MDBox sx={{ marginTop: '12px' }}>
          <TabContext value={value}>
            <MDBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Desktop" value={'desktop'} />
                <Tab label="Mobile" value={'mobile'} />
              </Tabs>
            </MDBox>
            <TabPanel value={'desktop'} index={0} sx={{ padding: '24px 0' }}>
              <MDBox position="relative">
                <pre
                  style={{
                    height: '400px',
                    padding: '20px',
                    color: '#000',
                    backgroundColor: 'rgb(167 169 183 / 40%)',
                    borderRadius: '10px',
                    overflow: 'auto',
                  }}
                >
                  {script}
                </pre>
                <MDBox position="absolute" right="10px" top="10px">
                  <Tooltip title={'copy code'}>
                    <IconButton
                      color={copy ? 'success' : 'secondary'}
                      onClick={() => copyToClipboard()}
                    >
                      <Icon fontSize="medium">copy</Icon>
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
            </TabPanel>
            <TabPanel value={'mobile'} index={1} sx={{ padding: '24px 0' }}>
              <MDBox position="relative">
                <pre
                  style={{
                    height: '400px',
                    padding: '20px',
                    color: '#000',
                    backgroundColor: 'rgb(167 169 183 / 40%)',
                    borderRadius: '10px',
                    overflow: 'auto',
                  }}
                >
                  {script}
                </pre>
                <MDBox position="absolute" right="10px" top="10px">
                  <Tooltip title={'copy code'}>
                    <IconButton
                      color={copy ? 'success' : 'secondary'}
                      onClick={() => copyToClipboard()}
                    >
                      <Icon fontSize="medium">copy</Icon>
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
            </TabPanel>
          </TabContext>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

export default ModalCopyScript;
