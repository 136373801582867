/* eslint-disable no-unused-vars */
import React from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import MDTypography from 'components/MDTypography';
import { ThreeCircles } from 'react-loader-spinner';
import ShieldMain from '../ShieldMain/ShieldMain';

const Loading = () => {
  return (
    <Card sx={{ mt: 15.5 }}>
      <ShieldMain title="XStream Traffic" />
      <MDBox pt={4} pb={3} px={3} display={'flex'} justifyContent={'center'}>
        <ThreeCircles color="#1A73E8" innerCircleColor="#7b809a" width={55} />
      </MDBox>
    </Card>
  );
};

export default Loading;
