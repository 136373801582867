/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { ADD_VOUCHER } from '../mutations/addVoucher';
import { ALL_VOUCHERS } from '../queries/allVouchers';

/**
 * Internal dependencies
 */

export default function useAddVoucher() {
  const [addVoucher, { loading }] = useMutation(ADD_VOUCHER, {
    refetchQueries: [{ query: ALL_VOUCHERS }],
  });

  return { addVoucher, loading };
}
