import MDButton from '../../../components/MDButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MDTypography from '../../../components/MDTypography';

const useUsersColumns = () => {
  const navigate = useNavigate();

  const columns = [
    {
      Header: 'Email',
      accessor: 'email',
    },
    { Header: 'Name', accessor: 'profile.name' },
    {
      Header: 'Online',
      accessor: (user) => (
        <AccountCircleIcon
          fontSize="medium"
          color={user.status?.onlineUserDash ? 'success' : 'error'}
        />
      ),
      width: '5%',
    },
    {
      Header: 'email status',
      accessor: 'emailStatus',
      Cell: ({ row }) => {
        return row.original.verified_email ? 'verified' : 'unverified';
      },
    },
    {
      Header: 'username',
      accessor: 'username',
      Cell: ({ row }) => {
        return row.original.username || row.original.email;
      },
    },
    {
      Header: 'updated at',
      accessor: (user) => {
        const lastLogin = new Date(user.updatedAt);
        return lastLogin.getTime() ? (
          <MDTypography variant="caption">
            {lastLogin.toLocaleDateString()}
          </MDTypography>
        ) : (
          <MDTypography variant="caption">none</MDTypography>
        );
      },
    },
    {
      Header: 'Last login',
      accessor: (user) => {
        const lastLogin = new Date(user.status?.lastLogin?.date);
        return lastLogin.getTime() ? (
          <>
            <MDTypography variant="caption">
              {lastLogin.toLocaleDateString()}
            </MDTypography>
            <br />
            <MDTypography variant="caption">
              {lastLogin.toLocaleTimeString()}
            </MDTypography>
          </>
        ) : (
          <MDTypography variant="caption">none</MDTypography>
        );
      },
    },
    { Header: 'Balance', accessor: (user) => `${user.tokens || 0} tokens` },
    {
      Header: 'Nr of campaigns',
      accessor: 'nrOfCampaigns',
      Cell: ({ row }) => {
        let nrOfCampaigns = row.original.nrOfCampaigns;
        return nrOfCampaigns ? nrOfCampaigns : '0';
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      isSorted: false,
      Cell: ({ row }) => {
        return (
          <>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              style={{ marginRight: 5 }}
              onClick={() => navigate(`/users/edit/${row.original._id}`)}
            >
              edit
            </MDButton>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              style={{ marginRight: 5 }}
              onClick={() => navigate(`/users/manage/${row.original._id}`)}
            >
              manage
            </MDButton>
          </>
        );
      },
    },
  ];

  return columns;
};

export default useUsersColumns;
