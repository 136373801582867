/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { ADD_PARTNER } from '../mutations/addPartner';
import { ALL_PARTNERS } from '../queries/allPartners';

/**
 * Internal dependencies
 */

export default function useAddPartner() {
  const [addPartner, { loading }] = useMutation(ADD_PARTNER, {
    refetchQueries: [{ query: ALL_PARTNERS }],
  });

  return { addPartner, loading };
}
