/**
 * External dependencies
 */
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { ALL_VOUCHERS } from '../queries/allVouchers';

export default function useAllVouchers() {
  const { data, loading, error } = useQuery(ALL_VOUCHERS, {
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
  };
}
