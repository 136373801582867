import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { useUserProfileById } from 'utils/queries/queries';
import Loading from '../../../components/Loading';
const UserProfileContext = createContext({});

export const UserProfileProvider = ({ children }) => {
  const { triggerGetUserById } = useUserProfileById();
  const { id } = useParams();
  const [avatarURL, setAvatarURL] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUserById = useCallback(async () => {
    const res = await triggerGetUserById({
      variables: { id },
    });
    setUser(res.data?.getUserById);
    setLoading(false);
  }, [id, triggerGetUserById]);

  useEffect(() => {
    if (!user) {
      getUserById();
    }
  }, [getUserById, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <UserProfileContext.Provider
      value={{
        user,
        avatarURL,
        setAvatarURL,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
export const useProfile = () => useContext(UserProfileContext);
