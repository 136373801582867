import {
  CardActions,
  CardContent,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';
import Card from '@mui/material/Card';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { useState, useEfect } from 'react';

const QACard = ({ faq, handleDelete, updateFaq }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [question, setQuestion] = useState(faq.question);
  const [answer, setAnswer] = useState(faq.answer);

  const handleEdit = () => {
    setIsDisabled(false);
  };

  const handleSave = () => {
    setIsDisabled(true);
    const updatedFaq = { ...faq, question, answer };
    updateFaq(updatedFaq);
  };

  const handleChange = (e) => {
    e.target.name === 'question'
      ? setQuestion(e.target.value)
      : setAnswer(e.target.value);
  };

  return (
    <>
      <Card sx={{ boxShadow: 3 }}>
        <CardActions
          sx={{ mb: -3.5, pr: 3, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Tooltip title="Edit" placement="top-start">
            <IconButton onClick={handleEdit} size="small">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save" placement="top">
            <IconButton onClick={handleSave} size="small">
              <SaveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top-end">
            <IconButton
              size="small"
              onClick={() => {
                handleDelete(faq.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
        <CardContent sx={{ pt: 0, mb: -1.5 }}>
          <Stack spacing={-1.5}>
            <Typography>
              <TextField
                size="small"
                margin="normal"
                name="question"
                label="Question"
                variant="standard"
                fullWidth
                disabled={isDisabled}
                onChange={(e) => handleChange(e)}
                value={question}
              />
            </Typography>
            <Typography>
              <TextField
                size="small"
                multiline
                margin="normal"
                name="answer"
                label="Answer"
                variant="standard"
                fullWidth
                disabled={isDisabled}
                onChange={handleChange}
                value={answer}
              />
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default QACard;
