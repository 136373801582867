import { gql } from '@apollo/client';

export const GET_USER_TRANSACTIONS = gql`
  query GetUserTransactions($userId: ID!) {
    getUserTransactions(userId: $userId) {
      _id
      userID
      label
      amount
      status
      date
    }
  }
`;
