import * as React from 'react';

import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';

const Shield = () => {
  return (
    <MDBox
      variant="gradient"
      bgColor="info"
      borderRadius="lg"
      coloredShadow="info"
      mx={2}
      mt={-3}
      p={2}
      mb={1}
      textAlign="center"
      minHeight={186}
    >
      <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
        XStream Traffic
      </MDTypography>
      <MDTypography variant="button" color="white" mt={1}>
        Admin Sign in
      </MDTypography>
    </MDBox>
  );
};

export default Shield;
