/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';
import useUserLogIn from '../../utils/hooks/use-userLogIn';
import useAuthContext from '../../utils/authentication/use-AuthContext';

//Formik
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import { Form, Formik } from 'formik';
import form from './schemas/form';
import PasswordField from '../../components/PasswordField/PasswordField';
import FormField from '../../components/FormField';
import Shield from './components/Shield';
import jwt_decode from 'jwt-decode';

function AdminSignIn() {
  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const {
    formId,
    formField: { email, password },
  } = form;

  const { loginUser } = useUserLogIn();
  const { setSessionToken } = useAuthContext();
  const navigate = useNavigate();

  function handleLogin(values, actions) {
    const { email, password } = values;
    loginUser({
      variables: {
        input: { email, password },
      },
    })
      .then(({ data }) => {
        //TODO AuthRoute GuestRoute
        if (data) {
          const token = data.loginUser.loginToken;
          if (token) {
            const user = jwt_decode(token);
            if (user.userType === 'admin') {
              setSessionToken(token);
              actions.setSubmitting(false);
              actions.resetForm();
              navigate('/sites', { replace: true });
            } else {
              actions.setSubmitting(false);
              actions.resetForm();
            }
          }
        }
      })
      .catch((err) => {
        actions.setSubmitting(false);
        actions.resetForm();
        //TODO de inlocuit log cu tratarea erorii
        console.log(err);
      });
  }

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ minWidth: 300 }}>
        <Shield />
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleLogin}
            validationSchema={validations}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <FormField
                  type={email.type}
                  label={email.label}
                  name={email.name}
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.value.toLowerCase());
                  }}
                />
                <PasswordField
                  formData={{
                    formField: { email, password },
                    values,
                    touched,
                    errors,
                  }}
                />
                <MDBox display="flex" alignItems="center" ml={-1} my={2.5}>
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                  >
                    &nbsp;&nbsp;Remember me
                  </MDTypography>
                </MDBox>
                <MDButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                >
                  sign in
                </MDButton>
              </Form>
            )}
          </Formik>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default AdminSignIn;
