import { useState } from 'react';
import { Card, Grid } from '@mui/material';
import MDButton from 'components/MDButton';
import React from 'react';
import Modal from '@mui/material/Modal';
import MDBox from 'components/MDBox';
import Switch from '@mui/material/Switch';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import { useSuspendUser } from '../../../../utils/hooks/use-allUsers';
import { useAdminEditUserTokens } from '../../../../utils/mutations/mutations';
import { toast } from 'react-toastify';
import useUserDelete from '../../../../utils/hooks/use-user-delete';
import { useNavigate } from 'react-router-dom';
import { GET_USER_PROFILE_BY_ID } from '../../../../utils/queries/queries';

function UserButtons({ currentUser }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  // const { _id: userId } = currentUser;
  const { suspendUser } = useSuspendUser();
  const { adminEditUserTokens } = useAdminEditUserTokens();
  const { removeUser } = useUserDelete();

  const navigate = useNavigate();

  const [modalStatus, setModalStatus] = useState({
    deleteIsOpen: false,
    addIsOpen: false,
    suspendIsOpen: false,
    removeIsOpen: false,
    checked: !!currentUser?.suspended,
  });

  const [tokenValue, setTokenValue] = useState(0);

  const handleSuspend = async () => {
    suspendUser({ variables: { userId: currentUser._id } }).then((data) => {
      setModalStatus({
        ...modalStatus,
        suspendIsOpen: false,
        checked: !modalStatus.checked,
      });
    });
  };

  const updateTokenValue = (e) => {
    Number(e.target.value)
      ? setTokenValue(Math.abs(Number(e.target.value)))
      : setTokenValue(0);
  };

  const handleCloseTokensModal = () => {
    setModalStatus({ ...modalStatus, addIsOpen: false, removeIsOpen: false });
    setTokenValue(0);
  };

  const handleUpdateTokens = (actionType) => {
    toast.promise(
      adminEditUserTokens({
        variables: {
          actionType: actionType,
          tokens: tokenValue,
          userId: currentUser._id,
        },
        refetchQueries: [
          { query: GET_USER_PROFILE_BY_ID, variables: { id: currentUser._id } },
        ],
      }),
      {
        pending: 'Pending',
        success: `Success, tokens ${actionType}ed!`,
        error: {
          render({ data }) {
            return data.message;
          },
        },
      }
    );
    handleCloseTokensModal();
  };

  const handleRemoveUser = () => {
    toast.promise(
      removeUser({ variables: { removeUserId: currentUser._id } }),
      {
        pending: 'pending',
        success: {
          render() {
            navigate('/users', { replace: true });
            return 'User deleted';
          },
        },
        error: {
          render({ data }) {
            return data.message;
          },
        },
      }
    );
    setModalStatus({ ...modalStatus, deleteIsOpen: false });
  };

  return (
    <>
      <Grid container>
        <Grid
          sx={{ ml: { xs: 7, lg: 0, md: 0 } }}
          pb={3}
          container
          justifyItems="space-around"
          justifyContent="space-around"
        >
          <Grid
            item
            container
            spacing={2}
            sm={4}
            md={5}
            lg={5}
            direction="column"
            pb={3}
          >
            <Grid item md={5} lg={2}>
              <MDButton
                onClick={(e) =>
                  setModalStatus({ ...modalStatus, addIsOpen: true })
                }
                sx={{ width: '210px' }}
                px={3}
                variant="contained"
                disableElevation
                color="dark"
                size="medium"
              >
                Add Tokens
              </MDButton>
            </Grid>
            <Grid item md={5} lg={2}>
              <MDButton
                onClick={(e) =>
                  setModalStatus({ ...modalStatus, removeIsOpen: true })
                }
                sx={{ width: '210px' }}
                px={3}
                variant="contained"
                color="dark"
                disableElevation
                size="medium"
              >
                Remove Tokens
              </MDButton>
            </Grid>
          </Grid>
          <Grid
            item
            container
            sm={4}
            md={4}
            lg={2.5}
            spacing={2}
            direction="column"
          >
            <Grid item lg={1}>
              <MDButton
                onClick={(e) =>
                  setModalStatus({ ...modalStatus, suspendIsOpen: true })
                }
                sx={{ width: '210px' }}
                px={3}
                disableElevation
                variant="contained"
                color="dark"
                size="small"
              >
                {!modalStatus.checked ? 'suspend user' : 'unsuspend user'}
                <Switch
                  checked={modalStatus.checked}
                  size="medium"
                  sx={{ mt: '-6px' }}
                />
              </MDButton>
            </Grid>
            <Grid item md={4} lg={2}>
              <MDButton
                onClick={(e) =>
                  setModalStatus({ ...modalStatus, deleteIsOpen: true })
                }
                sx={{ width: '210px' }}
                px={3}
                variant="contained"
                color="dark"
                disableElevation
                size="medium"
              >
                delete user
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Modals */}

      <Modal
        open={modalStatus.addIsOpen}
        onClose={handleCloseTokensModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { sm: 425 }, minHeight: 155 }}>
          <MDBox textAlign="center" mt={4} sx={{ px: { xs: 0.25 } }}>
            <MDTypography variant="h6" color="secondary">
              How many tokens do you wish to add ?
            </MDTypography>
            <MDInput
              fullWidth
              sx={{ mt: 3 }}
              label="How many tokens to add ?"
              value={tokenValue}
              onChange={(e) => updateTokenValue(e)}
            />
          </MDBox>
          <MDBox
            pb="10px"
            mt={3}
            px={3}
            display="flex"
            justifyContent="space-between"
          >
            <MDButton
              variant="gradient"
              color="dark"
              onClick={handleCloseTokensModal}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={() => handleUpdateTokens('add')}
            >
              Add Tokens
            </MDButton>
          </MDBox>
        </Card>
      </Modal>

      <Modal
        open={modalStatus.removeIsOpen}
        onClose={handleCloseTokensModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { sm: 425 }, minHeight: 155 }}>
          <MDBox textAlign="center" mt={4} sx={{ px: { xs: 0.25 } }}>
            <MDTypography variant="h6" color="secondary">
              How many tokens do you wish to remove ?
            </MDTypography>
            <MDInput
              sx={{ mt: 3 }}
              label="How many tokens to remove ?"
              fullWidth
              value={tokenValue}
              onChange={(e) => updateTokenValue(e)}
            />
          </MDBox>
          <MDBox
            pb="10px"
            mt={3}
            px={3}
            display="flex"
            justifyContent="space-between"
          >
            <MDButton
              variant="gradient"
              color="dark"
              onClick={handleCloseTokensModal}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={() => handleUpdateTokens('remove')}
            >
              Remove Tokens
            </MDButton>
          </MDBox>
        </Card>
      </Modal>

      <Modal
        open={modalStatus.deleteIsOpen}
        onClose={(e) => {
          setModalStatus({ ...modalStatus, deleteIsOpen: false });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { sm: 425 }, minHeight: 155 }}>
          <MDBox textAlign="center" mt={4} sx={{ px: { xs: 0.25 } }}>
            <MDTypography variant="h6" color="secondary">
              Are you sure you want to delete this user ?
            </MDTypography>
          </MDBox>
          <MDBox
            pb="10px"
            mt={3}
            px={3}
            display="flex"
            justifyContent="space-between"
          >
            <MDButton
              variant="gradient"
              color="dark"
              onClick={(e) => {
                setModalStatus({ ...modalStatus, deleteIsOpen: false });
              }}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={handleRemoveUser}
            >
              Delete User
            </MDButton>
          </MDBox>
        </Card>
      </Modal>

      <Modal
        open={modalStatus.suspendIsOpen}
        onClose={(e) => {
          setModalStatus({ ...modalStatus, suspendIsOpen: false });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { sm: 425 }, minHeight: 155 }}>
          <MDBox textAlign="center" mt={4} sx={{ px: { xs: 0.25 } }}>
            <MDTypography variant="h6" color="secondary">
              {!modalStatus.checked
                ? 'Are you sure you want to suspend this user ?'
                : 'Are you sure you want to unsuspend this user ?'}
            </MDTypography>
          </MDBox>
          <MDBox
            pb="10px"
            mt={3}
            px={3}
            display="flex"
            justifyContent="space-between"
          >
            <MDButton
              variant="gradient"
              color="dark"
              onClick={(e) => {
                setModalStatus({ ...modalStatus, suspendIsOpen: false });
              }}
            >
              Cancel
            </MDButton>
            <MDButton variant="gradient" color="dark" onClick={handleSuspend}>
              {!modalStatus.checked ? 'suspend user' : 'unsuspend user'}
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
    </>
  );
}

export default UserButtons;
