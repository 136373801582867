/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const CAMPAIGN_EDIT = gql`
  mutation Mutation($editCampaignId: ID!, $input: CampaignInput) {
    editCampaign(id: $editCampaignId, input: $input) {
      node {
        _id
        updatedAt
        category
        subCategory
        preferredChannels
        serviceType
        duration {
          startDate
          endDate
        }
        userId
        tokens
        banners {
          siteId
          siteName
          siteDomain
          type
          imageUrl
          redirectUrl
        }
      }
      message
    }
  }
`;

export const CAMPAIGN_CREATE = gql`
  mutation Mutation($input: CampaignInput) {
    createCampaign(input: $input) {
      node {
        _id
        updatedAt
        category
        subCategory
        preferredChannels
        serviceType
        duration {
          startDate
          endDate
        }
        userId
        tokens
        banners {
          siteId
          siteName
          siteDomain
          type
          imageUrl
          redirectUrl
        }
      }
      message
    }
  }
`;
export const CAMPAIGN_DELETE = gql`
  mutation RemoveCampaign($removeCampaignId: ID!) {
    removeCampaign(id: $removeCampaignId) {
      node {
        _id
        updatedAt
        category
        subCategory
        preferredChannels
        serviceType
        duration {
          startDate
          endDate
        }
        userId
        tokens
        banners {
          siteId
          siteName
          siteDomain
          type
          imageUrl
          redirectUrl
        }
      }
      message
    }
  }
`;
