/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const SITE_EDIT = gql`
  mutation EditSite($siteId: ID!, $input: EditSiteInput) {
    editSite(id: $siteId, input: $input) {
      node {
        _id
        updatedAt
        name
        category
        domain
        subCategory
        preferredChannels
        serviceType
        banners {
          defaultImageUrl
          defaultRedirectUrl
          imageUrl
          redirectUrl
          type
        }
      }
      message
    }
  }
`;

export const SITE_CREATE = gql`
  mutation CreateSite($input: CreateSiteInput) {
    createSite(input: $input) {
      node {
        _id
        updatedAt
        name
        domain
        category
        subCategory
        preferredChannels
        serviceType
        banners {
          defaultImageUrl
          defaultRedirectUrl
          imageUrl
          redirectUrl
          type
        }
      }
      message
    }
  }
`;
export const SITE_DELETE = gql`
  mutation RemoveSite($removeSiteId: ID!) {
    removeSite(id: $removeSiteId) {
      node {
        _id
        updatedAt
        name
        domain
        category
        subCategory
        preferredChannels
        serviceType
        banners {
          defaultImageUrl
          defaultRedirectUrl
          imageUrl
          redirectUrl
          type
        }
      }
      message
    }
  }
`;
