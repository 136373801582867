/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: 'site-details',
  formField: {
    name: {
      name: 'name',
      label: 'Site name',
      type: 'text',
      errorMsg: 'Username is required.',
    },
    domain: {
      name: 'domain',
      label: 'Domain',
      type: 'text',
      errorMsg: 'Gender is required.',
    },
    category: {
      name: 'category',
      label: 'Category',
      type: 'text',
    },
    subCategory: {
      name: 'subCategory',
      label: 'Tags',
      type: 'text',
    },
    serviceType: {
      name: 'serviceType',
      label: 'Service Type',
      type: 'text',
    },
    blockedTraffic: {
      name: 'blockedTraffic',
      label: 'Blocked Traffic',
      type: 'text',
    },
  },
};
