/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_VOUCHERS = gql`
  query AllVouchers {
    allVouchers {
      _id
      code
      value
      userId
      redeemedAt
      createdAt
      userName
    }
  }
`;
