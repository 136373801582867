/**
 * External dependencies
 */
import { useMutation, useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { ALL_USERS, SUSPEND_USER } from '../queries/allUsers';

export function useAllUsers(filter) {
  const { data, loading, error } = useQuery(ALL_USERS, {
    variables: { filter },
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
  };
}

export function useSuspendUser() {
  const [suspendUser, { data, loading, error }] = useMutation(SUSPEND_USER);

  return {
    suspendUser,
    data,
    loading,
    error,
  };
}
