/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';

// Base styles
import breakpoints from 'assets/theme/base/breakpoints';

// Components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Blocks
import DashboardLayout from 'blocks/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../components/DashboardNavbar';
import Footer from 'components/Footer';
import DataTable from '../../components/DataTable';

import useUsersColumns from './hooks/useUsersColumns';
import { useAllUsers } from '../../utils/hooks/use-allUsers';
import LoadingBasic from '../../components/LoadingBasic';

const Users = () => {
  const columns = useUsersColumns();
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState(0);

  const { data, loading } = useAllUsers();
  const allUsers = data?.allUsers?.edges;

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  function tableData(tabValue) {
    switch (tabValue) {
      case 1:
        return allUsers.filter(
          (row) => row.status.onlineUserDash || row.status.onlineAdminDash
        );
      case 2:
        return allUsers.filter((row) => !row.verified_email);
      case 3:
        return allUsers.filter((row) => row.verified_email && !row.suspended);
      case 4:
        return allUsers.filter((row) => row.suspended);
      default:
        return allUsers;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} minHeight={{ lg: '89vh' }}>
        <Card sx={{ minHeight: '60vh' }}>
          <MDBox
            mt={-3}
            mx={4}
            bgColor="info"
            borderRadius="lg"
            minHeight="80px"
            textAlign="center"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MDTypography variant="h3" color="light" fontWeight="regular">
              Users
            </MDTypography>
          </MDBox>
          {loading || !allUsers ? (
            <LoadingBasic />
          ) : (
            <>
              <MDBox
                px={4}
                pt={2.5}
                pb={1.5}
                lineHeight={1}
                display="flex"
                alignItems="center"
                sx={{
                  justifyContent: { xs: 'center', sm: 'flex-end' },
                  minHeight: '70px', //not required when add button is visible
                }}
              >
                {/*<MDButton variant="contained" color="info">*/}
                {/*  Add user*/}
                {/*</MDButton>*/}
              </MDBox>
              <MDBox
                pl={5}
                pr={5}
                lineHeight={2}
                display="flex"
                alignItems="center"
              >
                <Grid item xs={12} sm={8} lg={7}>
                  <Tabs
                    orientation={tabsOrientation}
                    value={tabValue}
                    onChange={handleSetTabValue}
                  >
                    <Tab label="All" />
                    <Tab label="Online" />
                    <Tab label="Unverified" />
                    <Tab label="Active" />
                    <Tab label="Suspended" />
                  </Tabs>
                </Grid>
              </MDBox>
              <DataTable
                tableData={tableData(tabValue)}
                columnsArray={columns}
                canSearch
              />
            </>
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};
export default Users;
