/**
 * External dependencies
 */
import React from 'react';
import { ApolloProvider } from '@apollo/client';

/**
 * Internal dependencies
 */
import apolloClient from 'utils/apollo-client/apollo-client';
import useAuthContext from '../authentication/use-AuthContext';

const ApolloClientProvider = ({ children }) => {
  const { sessionToken } = useAuthContext();

  const token = sessionToken || '';

  return (
    <ApolloProvider client={apolloClient(token)}>{children}</ApolloProvider>
  );
};

export default ApolloClientProvider;
