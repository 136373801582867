import { Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import React from 'react';

const ConfirmationModal = ({ modalShow, setModalShow, handleAction }) => {
  return (
    <Modal
      open={modalShow}
      onClose={() => setModalShow(false)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MDBox
        bgColor="white"
        width="100%"
        maxWidth="507px"
        borderRadius="12px"
        sx={{
          boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.25)',
          padding: '16px 8px',
        }}
      >
        <MDTypography
          sx={{
            fontSize: '16px',
            lineHeight: '16px',
            color: '#7B809A',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          Are you sure?
        </MDTypography>

        <MDBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <MDButton
            color="dark"
            variant="outlined"
            onClick={() => setModalShow(false)}
          >
            Cancel
          </MDButton>
          <MDButton color="dark" onClick={handleAction}>
            Yes
          </MDButton>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

export default ConfirmationModal;
