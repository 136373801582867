import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useSiteById,
  useSiteCreate,
  useSiteDelete,
  useSiteEdit,
} from 'utils/hooks/use-allSites';
import { useSubcategories } from 'utils/queries/queries';
import { toast } from 'react-toastify';

const SiteContext = createContext({});

export const SiteProvider = ({ children }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { getSiteByIdQuery } = useSiteById();
  const { data } = useSubcategories();
  const { editSite } = useSiteEdit();
  const { createSite } = useSiteCreate();
  const { deleteSite } = useSiteDelete();
  const [loading, setIsLoading] = useState(false);
  const [site, setSite] = useState(null);
  const [bannersSet, setBannersSet] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleDeleteSite = async (siteId) => {
    const res = await deleteSite({
      variables: {
        removeSiteId: siteId,
      },
    });

    if (!res.errors) {
      setConfirmationModal(false);
      navigate('/sites');
    }
  };

  const setBanner = (type, banner) => {
    console.log(type, banner);
    let myArr = bannersSet;
    let myArrIndex = myArr.findIndex((obj) => obj.type === type);
    let myArrUpdated = [...myArr];
    if (myArrIndex >= 0) {
      myArrUpdated = myArr.map((obj) =>
        obj.type === type ? { ...banner, type: type } : obj
      );
    } else {
      myArrUpdated.push({ type, ...banner });
    }
    setBannersSet(myArrUpdated);
  };

  const getSiteById = useCallback(async () => {
    setIsLoading(true);
    const currentSite = await getSiteByIdQuery({
      variables: { id },
    });
    setSite(currentSite.data.getSiteById);
    setBannersSet(currentSite.data.getSiteById.banners);
    setIsLoading(false);
  }, [getSiteByIdQuery, id]);

  useEffect(() => {
    if (!site && pathname.includes('edit')) {
      getSiteById();
    }
  }, [getSiteById, pathname, site]);

  useEffect(() => {
    if (data && !loading) {
      setSubcategories(data.allSubcategories.map((item) => item.name));
    }
  }, [data, loading]);

  const handleSubmit = async (values) => {
    const {
      name,
      domain,
      category,
      preferredChannels,
      serviceType,
      subCategory,
    } = values;

    if (pathname.includes('edit')) {
      const res = await editSite({
        variables: {
          siteId: site._id,
          input: {
            banners: bannersSet,
            name,
            domain,
            category,
            preferredChannels,
            serviceType,
            subCategory,
          },
        },
      });

      if (!res.error) {
        toast.success('Save success!');
        // navigate(`/sites`);
      }
    } else {
      if (
        name !== '' &&
        domain !== '' &&
        category.length > 0 &&
        serviceType.length > 0 &&
        subCategory.length > 0
      ) {
        const res = await createSite({
          variables: {
            input: {
              banners: bannersSet,
              name,
              domain,
              category,
              preferredChannels,
              serviceType,
              subCategory,
            },
          },
        });
        navigate(`/sites/edit/${res.data?.createSite?.node?._id}`);
      } else {
        console.log('error');
      }
    }
  };

  // if (loading) {
  //   return <LoadingBasic />;
  // }

  return (
    <SiteContext.Provider
      value={{
        site,
        subcategories,
        bannersSet,
        setBanner,
        handleSubmit,
        handleDeleteSite,
        confirmationModal,
        setConfirmationModal,
        loading,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
export const useSite = () => useContext(SiteContext);
