/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const USER_LOGIN = gql`
  mutation UserLogIn($input: loginUserForm) {
    loginUser(input: $input) {
      loginToken
      registrationWizard
    }
  }
`;
