import * as React from 'react';

import MDBox from '../MDBox';
import MDTypography from '../MDTypography';
import MDAvatar from '../MDAvatar';

const ShieldMain = ({ title, avatar }) => {
  return (
    <MDBox position="relative">
      <MDBox
        mt={-3}
        mx={4}
        bgColor="info"
        borderRadius="lg"
        minHeight="80px"
        textAlign="center"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MDTypography variant="h3" color="light" fontWeight="regular">
          {title}
        </MDTypography>
      </MDBox>
      {avatar && (
        <MDAvatar
          src={avatar}
          size="xxl"
          sx={{ position: 'absolute', right: 80, top: -40 }}
        />
      )}
    </MDBox>
  );
};

export default ShieldMain;
