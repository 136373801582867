/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const REMOVE_PARTNER = gql`
  mutation RemovePartner($partnerId: String) {
    removePartner(partnerId: $partnerId)
  }
`;
