const timeSpan = 90; //years in list
export const adultAge = 18;

export const adultLimitDate = new Date(
  new Date(
    new Date().setFullYear(new Date().getFullYear() - adultAge)
  ).setHours(0, 0, 0, 0)
);

export const nextHour = () =>
  new Date(
    new Date(new Date().setFullYear(new Date().getFullYear())).setHours(
      new Date().getHours() + 1,
      0,
      0,
      0
    )
  );

export const next4Hours = () =>
  new Date(
    new Date(new Date().setFullYear(new Date().getFullYear())).setHours(
      new Date().getHours() + 5,
      0,
      0,
      0
    )
  );

export const nextXSec = (xSec) =>
  new Date(
    new Date(new Date().setFullYear(new Date().getFullYear())).setHours(
      new Date().getHours(),
      new Date().getMinutes(),
      new Date().getSeconds() + xSec,
      0
    )
  );

export const getStringDate = (date) => {
  return `${String(date.getDate()).padStart(2, '0')}.${String(
    date.getMonth() + 1
  ).padStart(2, '0')}.${date.getFullYear()}`;
};

export const getArrDate = (stringDate) => {
  if (!stringDate) {
    stringDate = getStringDate(adultLimitDate);
  }
  return stringDate
    .trim()
    .split(/[-.]/)
    .map((item, index) => {
      if (index === 1) return Number(item) - 1;
      return Number(item);
    });
};

export const getYears = () => {
  const start = adultLimitDate.getFullYear() - timeSpan;
  let range = [...Array(timeSpan).keys()].map((y) => String(y + start + 1));
  return range.reverse();
};
