import { Grid, Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import React from 'react';
import Divider from '@mui/material/Divider';
import { UAParser } from 'ua-parser-js';

function UsersDetail({ currentUser }) {
  const labelParams = { variant: 'h6', color: 'text' };
  const detailsParams = { variant: 'button', color: 'text' };
  const registeredDate = new Date(currentUser.updatedAt);
  const lastLoginDate = new Date(currentUser.status?.lastLogin?.date);
  const parsedAgent = new UAParser(currentUser.status?.lastLogin?.userAgent);
  const browser = parsedAgent.getBrowser();
  const os = parsedAgent.getOS();
  return (
    <Stack
      direction="row"
      mx={6}
      my={4}
      width="100%"
      justifyContent="space-between"
      divider={
        <Divider
          orientation="vertical"
          flexItem
          variant="fullWidth"
          sx={{ border: '0.5px solid grey' }}
        />
      }
    >
      <Stack width="100%">
        <Grid container alignItems="center">
          <Grid item xs={6} alignItems="center">
            <MDTypography {...labelParams}>Registered</MDTypography>
          </Grid>
          <Grid item xs={6}>
            <MDTypography {...detailsParams}>
              {registeredDate.toLocaleDateString()}
            </MDTypography>
            &nbsp;&nbsp;
            <MDTypography {...detailsParams}>
              {registeredDate.toLocaleTimeString()}
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <MDTypography {...labelParams}>IP</MDTypography>
          </Grid>
          <Grid item xs={6}>
            <MDTypography {...detailsParams}>
              {currentUser.status?.lastLogin?.ipAddr ?? 'unknown'}
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <MDTypography {...labelParams}>Online</MDTypography>
          </Grid>
          <Grid item xs={6}>
            <MDTypography {...detailsParams}>
              {currentUser.status?.onlineUserDash ? 'yes' : 'no'}
            </MDTypography>
          </Grid>
        </Grid>
      </Stack>
      <Stack width="100%">
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <MDTypography {...labelParams}>Last Login</MDTypography>
          </Grid>
          <Grid item xs={7}>
            {!Number.isNaN(lastLoginDate.getTime()) ? (
              <>
                <MDTypography {...detailsParams}>
                  {lastLoginDate.toLocaleDateString()}
                </MDTypography>
                &nbsp;&nbsp;
                <MDTypography {...detailsParams}>
                  {lastLoginDate.toLocaleTimeString()}
                </MDTypography>
              </>
            ) : (
              <MDTypography {...detailsParams}>unknown</MDTypography>
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <MDTypography {...labelParams}>Suspended</MDTypography>
          </Grid>
          <Grid item xs={7}>
            <MDTypography {...detailsParams}>
              {currentUser.suspended ? 'yes' : 'no'}
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <MDTypography {...labelParams}>Device Type</MDTypography>
          </Grid>
          <Grid item xs={7}>
            <MDTypography variant="caption" color="text">
              {`${browser.name}${browser.version}/${os.name}${os.version}`}
            </MDTypography>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default UsersDetail;
