import { gql, useLazyQuery, useQuery } from '@apollo/client';

const GET_USER_PROFILE = gql`
  query getUserProfileQuery {
    user {
      _id
      updatedAt
      email
      username
      profile {
        registrationWizard
        name
        avatar
        gender
        birthDate
      }
      socialProfile {
        instagram
        twitter
        snapchat
        onlyfans
      }
      status {
        onlineAdminDash
        onlineUserDash
        lastLogin {
          userAgent
          ipAddr
          date
        }
      }
      blockCountries
      workingSites
      nrOfCampaigns
      tokens
    }
  }
`;

export const GET_USER_PROFILE_BY_ID = gql`
  query GetUserById($id: String!) {
    getUserById(id: $id) {
      _id
      updatedAt
      services {
        password
        google {
          accessToken
          idToken
          id
          email
          name
          given_name
          family_name
          picture
        }
        facebook {
          accessToken
          id
          email
          name
          first_name
          last_name
          link
          gender
          locale
          verified
        }
        apple {
          iss
          aud
          sub
          nonce
          c_hash
          email
          email_verified
          is_private_email
          auth_time
          nonce_supported
        }
      }
      email
      username
      profile {
        registrationWizard
        name
        avatar
        gender
        birthDate
      }
      socialProfile {
        instagram
        twitter
        snapchat
        onlyfans
      }
      status {
        onlineAdminDash
        onlineUserDash
        lastLogin {
          userAgent
          ipAddr
          date
        }
      }
      blockCountries
      workingSites
      nrOfCampaigns
      banners {
        type
        imageUrl
      }
      tokens
      suspended
    }
  }
`;

const GET_AVAILABLE_SPOTS = gql`
  query AllAvailableSpots {
    allAvailableSpots {
      countOfSites
      bannerType
      tokenPrice
    }
  }
`;

export const GET_USER_TRANSACTIONS = gql`
  query AllTransactionsUser {
    allTransactionsUser {
      _id
      userID
      label
      amount
      status
      date
    }
  }
`;

export const CHECK_EMAIL = gql`
  query checkEmailQuery($email: String!) {
    checkEmail(email: $email) {
      email
      found
    }
  }
`;

export const ALL_SUBCATEGORIES = gql`
  query AllSubcategories {
    allSubcategories {
      _id
      name
    }
  }
`;

export function useCheckEmail(email = '') {
  const [triggerCheckEmail, { data, loading, error, refetch }] = useLazyQuery(
    CHECK_EMAIL,
    {
      variables: {
        email: email,
      },
    }
  );

  return {
    triggerCheckEmail,
    data,
    loading,
    error,
    refetch,
  };
}

export function useUserTransactions() {
  const { data, loading, error } = useQuery(GET_USER_TRANSACTIONS);

  return {
    data,
    loading,
    error,
  };
}

export function useUserProfile() {
  const { data, loading, error } = useQuery(GET_USER_PROFILE);

  return {
    data,
    loading,
    error,
  };
}

export function useUserProfileById(id) {
  const [triggerGetUserById, { data, loading, error, refetch }] = useLazyQuery(
    GET_USER_PROFILE_BY_ID,
    {
      variables: {
        id: id,
      },
    }
  );

  return {
    triggerGetUserById,
    data,
    loading,
    error,
    refetch,
  };
}

export function useProfileById(id) {
  const { data, loading, error } = useQuery(GET_USER_PROFILE_BY_ID, {
    variables: {
      id: id,
    },
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
  };
}

export function useAvailableSpots() {
  const { data, loading, error } = useQuery(GET_AVAILABLE_SPOTS);

  return {
    data,
    loading,
    error,
  };
}

export function useSubcategories() {
  const { data, loading, error } = useQuery(ALL_SUBCATEGORIES);

  return {
    data,
    loading,
    error,
  };
}
