/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui material components
import {
  Card,
  Grid,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';

// Material Dashboard 2 PRO React components
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'blocks/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../components/DashboardNavbar';
import Footer from 'blocks/Footer';

import Autocomplete from '@mui/material/Autocomplete';

//components
import CampaignDetailCard from './components/CampaignDetailCard';
import MDButton from 'components/MDButton';
import ShieldMain from '../../components/ShieldMain/ShieldMain';
import MDBox from '../../components/MDBox';
import BannersDisplay from '../../components/BannersDisplay/BannersDisplay';
import MDInput from '../../components/MDInput';
import { categories, subCat } from '../../utils/data/categories';
import {
  useCampaignById,
  useCampaignDelete,
} from '../../utils/hooks/use-allCampaigns';
import LoadingBasic from '../../components/LoadingBasic';
import { toast } from 'react-toastify';

const CampaignEdit = () => {
  const { id } = useParams();
  const { data } = useCampaignById(id);
  const { deleteCampaign } = useCampaignDelete();

  const ShortId = require('id-shorter');
  const idShortener = ShortId();

  const siteDomainList = data
    ? [
        ...new Set(
          data.getCampaignById.banners.map((banner) => banner.siteDomain)
        ),
      ]
    : [];

  const [payload, setPayload] = useState(campaignTemplate);

  useEffect(() => {
    setPayload({ ...payload, ...data?.getCampaignById });
  }, [data]);

  const navigate = useNavigate();

  const localStartDate = new Date(payload?.duration?.startDate);
  const localEndDate = new Date(payload?.duration?.endDate);

  const handleCatSelect = (newValue) => {
    const updatedCategory = { ...payload, category: newValue };
    setPayload(updatedCategory);
  };
  const handleSubSelect = (newValue) => {
    const updatedCategory = { ...payload, subCategory: newValue };
    setPayload(updatedCategory);
  };

  const toggleChanSelect = (e) => {
    const name = e.target.name;
    let newSelection;
    if (payload.preferredChannels.includes(name)) {
      newSelection = payload.preferredChannels.filter((item) => item !== name);
    } else {
      newSelection = [...payload.preferredChannels, name];
    }
    setPayload({ ...payload, preferredChannels: newSelection });
  };

  const handleDeleteCampaign = (campaignId) => {
    toast.promise(
      deleteCampaign({
        variables: {
          removeCampaignId: campaignId,
        },
      }),
      {
        pending: 'Pending...',
        success: {
          render({ data }) {
            navigate('/campaigns');
            return data.data.removeCampaign.message;
          },
          icon: '🟢',
        },
        error: {
          render({ data }) {
            return data.message;
          },
        },
      }
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mt={{ xs: 7, sm: 15 }}
        maxWidth={950}
        mx="auto"
        minHeight={{ lg: '80vh' }}
      >
        <Card>
          <ShieldMain title={'Campaign Details'} />
          <MDTypography ml={4} mt={2} variant="h6" color="secondary">
            Campaign ID #{idShortener.encode(id)}
          </MDTypography>
          {!data ? (
            <MDBox height="40vh" pt="15vh">
              <LoadingBasic />
            </MDBox>
          ) : (
            <>
              <Grid mt={3} px={3} container spacing={2}>
                {/*////// CARDS AREA //////*/}
                <Grid
                  mb={2}
                  item
                  container
                  xs={12}
                  justifyContent="space-around"
                >
                  <Grid ml={0.5} item xs={11} sm={5} md={5} lg={2.8}>
                    <CampaignDetailCard
                      header="Number of sites"
                      content={
                        <MDTypography
                          variant="body1"
                          color="secondary"
                          sx={{ mt: 1.25 }}
                        >
                          {siteDomainList.length}
                        </MDTypography>
                      }
                    />
                  </Grid>
                  <Grid item xs={11} sm={5} md={5} lg={2.8}>
                    <CampaignDetailCard
                      header="Duration"
                      content={
                        <MDTypography
                          variant="body1"
                          color="secondary"
                          sx={{ mt: 1.25 }}
                        >
                          4 hours
                        </MDTypography>
                      }
                    />
                  </Grid>
                  <Grid item xs={11} sm={5} md={5} lg={2.8}>
                    <CampaignDetailCard
                      header="Tokens"
                      content={
                        <MDTypography
                          variant="body1"
                          color="secondary"
                          sx={{ mt: 1.25 }}
                        >
                          {payload.tokens} Tokens
                        </MDTypography>
                      }
                    />
                  </Grid>
                  <Grid item xs={11} sm={5} md={5} lg={2.9}>
                    <CampaignDetailCard
                      header="Selected banners"
                      content={
                        <MDBox display="flex" justifyContent="center">
                          <BannersDisplay
                            genWidth="100px"
                            genHeight="60px"
                            bgColor="white"
                            bannersColor="info"
                            {...payload.banners.reduce(
                              (acc, banner) => ({
                                ...acc,
                                [banner.type]: !!banner.redirectUrl,
                              }),
                              {}
                            )}
                          />
                        </MDBox>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container mx={4} rowSpacing={1.5} my={2}>
                  {/*////// CATEGORY //////*/}
                  <Grid item container xs={12} alignItems="baseline">
                    <Grid item xs={2}>
                      <MDTypography variant="h6" color="text">
                        Category
                      </MDTypography>
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        disabled
                        multiple
                        value={payload.category}
                        getOptionDisabled={(option) =>
                          payload.category.length >= 2 &&
                          !payload.category.includes(option)
                        }
                        onChange={(e, newValue) => {
                          handleCatSelect(newValue);
                        }}
                        options={categories}
                        renderInput={(params) => (
                          <MDInput
                            label=""
                            variant="standard"
                            fullWidth
                            {...params}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {/*////// SUB CATEGORY //////*/}
                  <Grid item container xs={12} alignItems="baseline">
                    <Grid item xs={2}>
                      <MDTypography variant="h6" color="text">
                        Sub-category
                      </MDTypography>
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        disabled
                        multiple
                        value={payload.subCategory}
                        getOptionDisabled={(option) =>
                          payload.subCategory.length >= 3 &&
                          !payload.subCategory.includes(option)
                        }
                        onChange={(e, newValue) => {
                          handleSubSelect(newValue);
                        }}
                        options={subCat}
                        renderInput={(params) => (
                          <MDInput
                            label=""
                            variant="standard"
                            fullWidth
                            {...params}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {/*////// CHANNELS //////*/}
                  <Grid item container xs={12} alignItems="baseline">
                    <Grid item xs={2}>
                      <MDTypography variant="h6" color="text">
                        Channels
                      </MDTypography>
                    </Grid>
                    <Grid item xs={8}>
                      <FormGroup>
                        <MDBox sx={{ display: 'flex' }}>
                          <FormControlLabel
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontWeight: 'regular',
                                color: '#7b809a',
                                ml: -0.25,
                              },
                            }}
                            control={
                              <Checkbox
                                disabled
                                disableRipple
                                name="livecam"
                                checked={payload.preferredChannels.includes(
                                  'livecam'
                                )}
                                onChange={toggleChanSelect}
                              />
                            }
                            label="Livecams"
                          />
                          <FormControlLabel
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontWeight: 'regular',
                                color: '#7b809a',
                                ml: -0.25,
                              },
                            }}
                            control={
                              <Checkbox
                                disabled
                                disableRipple
                                name="tube"
                                checked={payload.preferredChannels.includes(
                                  'tube'
                                )}
                                onChange={toggleChanSelect}
                              />
                            }
                            label="Tubes"
                          />
                        </MDBox>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  {/*////// DAY //////*/}
                  <Grid item container xs={12} alignItems="baseline">
                    <Grid item xs={2} alignItems="baseline">
                      <MDTypography variant="h6" color="text">
                        Day
                      </MDTypography>
                    </Grid>
                    <Grid item xs={8}>
                      <MDTypography
                        variant="button"
                        color="text"
                        fontWeight="regular"
                      >
                        {new Date(payload.updatedAt).toLocaleDateString()}
                      </MDTypography>
                    </Grid>
                  </Grid>
                  {/*////// TIME FRAME //////*/}
                  <Grid item container xs={12} alignItems="baseline">
                    <Grid item xs={2}>
                      <MDTypography variant="h6" color="text">
                        Time frame
                      </MDTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <MDTypography variant="caption">
                        {localStartDate.toLocaleTimeString()}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <MDTypography variant="caption">
                        {localEndDate.toLocaleTimeString()}
                      </MDTypography>
                    </Grid>
                  </Grid>
                  {/*////// SITES //////*/}
                  <Grid item container xs={12} alignItems="baseline">
                    <Grid item xs={2}>
                      <MDTypography variant="h6" color="text">
                        Sites
                      </MDTypography>
                    </Grid>
                    <Grid item xs={10}>
                      <MDTypography
                        variant="button"
                        color="text"
                        fontWeight="regular"
                      >
                        {siteDomainList.map((site, i, { length }) => (
                          <MDTypography
                            variant="caption"
                            color="secondary"
                            key={site}
                          >
                            {site}
                            {length - i - 1 ? `;\u00A0` : ''}
                          </MDTypography>
                        ))}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/*////// BUTTONS AREA //////*/}
              <Grid
                container
                mx={4}
                my={3}
                justifyContent="space-between"
                width="unset"
              >
                <Grid item>
                  <MDButton
                    variant="contained"
                    color="light"
                    size="medium"
                    onClick={() => navigate('/campaigns')}
                  >
                    Cancel
                  </MDButton>
                </Grid>
                <Grid item>
                  <MDButton
                    variant="contained"
                    color="dark"
                    size="medium"
                    onClick={() => handleDeleteCampaign(id)}
                  >
                    Delete
                  </MDButton>
                </Grid>
              </Grid>
            </>
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

const campaignTemplate = {
  updatedAt: '',
  category: [],
  subCategory: [],
  preferredChannels: [],
  serviceType: [],
  duration: {
    startDate: '',
    endDate: '',
  },
  userId: '',
  tokens: '',
  banners: [],
};

export default CampaignEdit;
