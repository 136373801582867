/* eslint-disable no-unused-vars */
import React from 'react';
import * as Yup from 'yup';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Autocomplete, Grid, SvgIcon } from '@mui/material';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import { Form, Formik } from 'formik';
import FormField from 'components/FormField';
import MDButton from 'components/MDButton';
import { useEditUser } from 'utils/mutations/mutations';
import {
  adultAge,
  getArrDate,
  getStringDate,
  getYears,
} from 'utils/dateUtils/dateUtils';
import sites from '../../../utils/data/sites';
import genders from '../../../utils/data/genders';
import months from '../../../utils/data/months';
import days from '../../../utils/data/days';
import useAllCountries from 'utils/hooks/use-allCountries';
import lod_str from 'lodash/string';
import { ReactComponent as Instagram } from '../../../utils/logos/instagram-alt.svg';
import { ReactComponent as SnapChat } from '../../../utils/logos/snapchat-square-2.svg';
import { ReactComponent as OnlyFans } from '../../../utils/logos/Onlyfans-icon-rounded.svg';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast, ToastContainer } from 'react-toastify';

const SignInSchema = Yup.object().shape({
  username: Yup.string().min(
    5,
    'Username is too short - should be 4 chars minimum'
  ),
  gender: Yup.string().required().notOneOf(['0'], 'Please pick a valid value!'),
  blockCountries: Yup.array(),
  workingSites: Yup.array(),
  instagram: Yup.string(),
  twitter: Yup.string(),
  snapchat: Yup.string(),
  onlyfans: Yup.string(),
});

const formObject = {
  username: {
    name: 'username',
    label: 'Username',
    type: 'text',
    errorMsg: 'Username is required.',
  },
  gender: {
    name: 'gender',
    label: 'Gender',
    type: 'text',
    errorMsg: 'Gender is required.',
  },
  blockCountries: {
    name: 'blockCountries',
    label: 'Block Countries',
    type: 'text',
    errorMsg: 'Block countries is required.',
  },
  workingSites: {
    name: 'workingSites',
    label: 'Sites',
    type: 'text',
    errorMsg: 'Sites is required.',
  },
  instagram: {
    name: 'socialProfile.instagram',
    label: 'Instagram',
    type: 'text',
    errorMsg: 'Instagram is required.',
  },
  twitter: {
    name: 'socialProfile.twitter',
    label: 'Twitter',
    type: 'text',
    errorMsg: 'Twitter is required.',
  },
  snapchat: {
    name: 'socialProfile.snapchat',
    label: 'SnapChat',
    type: 'text',
    errorMsg: 'SnapChat is required.',
  },
  onlyfans: {
    name: 'socialProfile.onlyfans',
    label: 'onlyfans',
    type: 'text',
    errorMsg: 'onlyfans is required.',
  },
};

const MyProfileEdit = ({ setIsEdit, userData }) => {
  const {
    username,
    gender,
    blockCountries,
    workingSites,
    instagram,
    twitter,
    snapchat,
    onlyfans,
  } = formObject;

  const { editUser, data, loading, error } = useEditUser();
  const { _id, email } = userData;
  const [DD, MM, YYYY] = getArrDate(userData.profile?.birthDate);
  const navigate = useNavigate();

  const initialValues = {
    username: userData.username ?? '',
    birthDate: userData.profile?.birthDate ?? '',
    gender: userData.profile?.gender ?? '',
    blockCountries: userData.blockCountries ?? [],
    workingSites: userData.workingSites ?? [],
    socialProfile: userData.socialProfile
      ? userData.socialProfile
      : {
          instagram: '',
          twitter: '',
          snapchat: '',
          onlyfans: '',
        },
  };

  const countries = useAllCountries()?.data?.allCountries ?? [];

  const handleSubmit = (values) => {
    const {
      birthDate,
      gender,
      socialProfile: { instagram, twitter, snapchat, onlyfans },
      username,
      blockCountries,
      workingSites,
    } = values;

    const profileEdited = {
      username,
      blockCountries,
      workingSites,
      profile: {
        gender,
        birthDate,
      },
      socialProfile: {
        instagram,
        twitter,
        snapchat,
        onlyfans,
      },
    };

    editUser({
      variables: { id: _id, input: profileEdited },
    }).then((res) => {
      toast(res.data.editUser.message, {
        onClose: () => {
          navigate('/users');
        },
      });
    });
  };

  return (
    <MDBox padding={{ md: '0 70px' }}>
      <ToastContainer autoClose={2000} />
      <Formik
        initialValues={initialValues}
        validationSchema={SignInSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          const { values, errors, touched, isValid, dirty, setFieldValue } =
            formik;
          return (
            <div className="container">
              <Form>
                <Grid item container xs={12} mb={1} columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type={username.type}
                      label={username.label}
                      name={username.name}
                      value={values.username}
                      placeholder={username.placeholder}
                      error={errors.username && touched.username}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      name={'email'}
                      label={'Email'}
                      value={email}
                      disabled
                      sx={{ pl: 1 }}
                    />
                  </Grid>
                </Grid>
                <MDTypography
                  fontWeight="bold"
                  sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    color: '#7B809A',
                    marginBottom: '5px',
                  }}
                >
                  I'm
                </MDTypography>
                <Grid item xs={12}>
                  <Autocomplete
                    disableClearable
                    options={genders}
                    getOptionLabel={(option) => lod_str.capitalize(option)}
                    onChange={(e, newValue) => {
                      setFieldValue('gender', newValue);
                    }}
                    value={values.gender}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" label="" />
                    )}
                  />
                </Grid>

                <MDTypography
                  fontWeight="bold"
                  sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    color: '#7B809A',
                    marginBottom: '5px',
                  }}
                >
                  Birth Date
                </MDTypography>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Autocomplete
                      disableClearable
                      options={months}
                      defaultValue={months[MM]}
                      onChange={(e, newValue) => {
                        setFieldValue(
                          'birthDate',
                          getStringDate(
                            new Date(`${YYYY}/
                              ${months.indexOf(newValue) + 1}/
                              ${DD}`)
                          )
                        );
                      }}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="standard"
                          label=""
                          FormHelperTextProps={{ style: { color: 'red' } }}
                          helperText={
                            !errors?.birthDate
                              ? ' '
                              : `At least ${adultAge} years!`
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      disableClearable
                      options={days}
                      value={DD.toString()}
                      onChange={(e, newValue) => {
                        setFieldValue(
                          'birthDate',
                          getStringDate(
                            new Date(`${YYYY}/
                              ${MM}/
                              ${parseInt(newValue, 10)}`)
                          )
                        );
                      }}
                      renderInput={(params) => (
                        <MDInput {...params} variant="standard" label="" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      disableClearable
                      options={getYears()}
                      value={YYYY.toString()}
                      onChange={(e, newValue) => {
                        setFieldValue(
                          'birthDate',
                          getStringDate(
                            new Date(`${parseInt(newValue, 10)}/
                              ${MM + 1}/
                              ${DD}`)
                          )
                        );
                      }}
                      renderInput={(params) => (
                        <MDInput {...params} variant="standard" label="" />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <MDTypography
                      fontWeight="bold"
                      sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#7B809A',
                        minWidth: '120px',
                        marginRight: '50px',
                      }}
                    >
                      Block Countries
                    </MDTypography>
                  </Grid>
                  <Grid item xs={8}>
                    <Autocomplete
                      multiple
                      value={values.blockCountries}
                      name={blockCountries.name}
                      options={countries.map((country) => country.label)}
                      onChange={(e, value) => {
                        setFieldValue(
                          'blockCountries',
                          value !== null ? value : initialValues.blockCountries
                        );
                      }}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          label=""
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <MDTypography
                      fontWeight="bold"
                      sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#7B809A',
                        minWidth: '120px',
                        marginRight: '50px',
                      }}
                    >
                      Sites
                    </MDTypography>
                  </Grid>
                  <Grid item xs={8}>
                    <Autocomplete
                      multiple
                      value={values.workingSites}
                      options={sites.map((site) => site.label)}
                      onChange={(e, value) => {
                        setFieldValue(
                          'workingSites',
                          value !== null ? value : initialValues.workingSites
                        );
                      }}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          name={workingSites.name}
                          label=" "
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <MDTypography
                  fontWeight="bold"
                  sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    color: '#7B809A',
                    my: '15px',
                  }}
                >
                  Social Media Links
                </MDTypography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <MDBox
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <SvgIcon
                        sx={{
                          width: '2em',
                          height: '2em',
                          mr: 1,
                        }}
                        color={'info'}
                      >
                        <Instagram />
                      </SvgIcon>
                      <FormField
                        type={instagram.type}
                        label={instagram.label}
                        name={instagram.name}
                        value={values?.socialProfile?.instagram}
                        placeholder={instagram.placeholder}
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDBox
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <TwitterIcon
                        sx={{
                          color: '#1A73E8',
                          width: '2em',
                          height: '2em',
                          mr: 1,
                        }}
                      />
                      <FormField
                        type={twitter.type}
                        label={twitter.label}
                        name={twitter.name}
                        value={values?.socialProfile?.twitter}
                        placeholder={twitter.placeholder}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDBox
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <SvgIcon
                        sx={{ width: '2em', height: '2em', mr: 1 }}
                        color={'info'}
                      >
                        <SnapChat />
                      </SvgIcon>
                      <FormField
                        type={snapchat.type}
                        label={snapchat.label}
                        name={snapchat.name}
                        value={values?.socialProfile?.snapchat}
                        placeholder={snapchat.placeholder}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDBox
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <SvgIcon sx={{ width: '2em', height: '1.6em', mr: 1 }}>
                        <OnlyFans />
                      </SvgIcon>
                      <FormField
                        type={onlyfans.type}
                        label={onlyfans.label}
                        name={onlyfans.name}
                        value={values?.socialProfile?.onlyfans}
                        placeholder={onlyfans.placeholder}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox
                  mt={3}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDButton
                    variant="gradient"
                    color="light"
                    onClick={() => navigate('/users')}
                  >
                    cancel
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    type="submit"
                    disabled={!(dirty && isValid)}
                  >
                    save
                  </MDButton>
                </MDBox>
              </Form>
            </div>
          );
        }}
      </Formik>
    </MDBox>
  );
};

export default MyProfileEdit;
