/**
 * External dependencies
 */
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { ALL_PARTNERS } from '../queries/allPartners';

export default function useAllPartners() {
  const { data, loading, error } = useQuery(ALL_PARTNERS);

  return {
    data,
    loading,
    error,
  };
}
