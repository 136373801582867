import React from 'react';

//components
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import MDTypography from 'components/MDTypography';
import { CardContent } from '@mui/material';

function CampaignDetailCard({ header, content }) {
  return (
    <Card sx={{ boxShadow: 4 }}>
      <CardHeader
        title={
          <MDTypography variant="subtitle2" lineHeight={1}>
            {header}
          </MDTypography>
        }
        sx={{ borderBottom: '1px #F0F2F5 solid', pb: 1 }}
      />
      <CardContent sx={{ mb: -1.5, minHeight: 92 }}>{content}</CardContent>
    </Card>
  );
}

export default CampaignDetailCard;
