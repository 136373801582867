import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import DataTable from '../../../../blocks/Tables/DataTable';
import React from 'react';
import useGetUserTransactions from '../../../../utils/hooks/use-getUserTransactions';
import LoadingBasic from '../../../../components/LoadingBasic';
import columns from './columnsUserTransactions';

const UserTransactions = ({ userId }) => {
  const { data, loading, error } = useGetUserTransactions(userId);
  const allUserTransactions = data?.getUserTransactions.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <Grid justifyContent="center" container>
      <Grid xs={11} mb={2} item lg={11}>
        <Card>
          {loading ? (
            <LoadingBasic />
          ) : (
            <DataTable
              tableData={allUserTransactions}
              columnsArray={columns}
              entriesPerPage={{ defaultValue: 5 }}
              canSearch
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserTransactions;
