/**
 * External dependencies
 */
import {
  ApolloClient,
  createHttpLink,
  split,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import jwt_decode from 'jwt-decode';

const apolloClient = (token) => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_API_URL,
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_WSS_URL,
    options: {
      reconnect: true,
      connectionParams: {
        authorization: token || null,
      },
    },
  });

  let wsStatus = 0;

  if (wsLink) {
    wsLink.subscriptionClient.on('connected', () => {
      if (wsStatus === 1) {
        wsStatus = 0;
        // HIDE UI MESSAGE
      }
    });

    wsLink.subscriptionClient.on('reconnected', () => {
      if (wsStatus === 1) {
        wsStatus = 0;
        // HIDE UI MESSAGE
      }
    });

    wsLink.subscriptionClient.on('disconnected', () => {
      if (token && wsStatus === 0) {
        wsStatus = 1;
      }
    });
  }

  const authLink = setContext(async (_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
      connectionParams: {
        authorization: token || null,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  // Automatically switching between WebSocket link or HTTP Link based on our query type
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink)
  );

  const cache = new InMemoryCache({
    addTypename: false,
  });

  return new ApolloClient({
    link: ApolloLink.from([errorLink, splitLink]),
    cache,
    rejectUnauthorized: false,
  });
};

export default apolloClient;
