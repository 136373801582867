import MDBox from '../MDBox';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import React from 'react';

const BannersDisplay = ({
  genWidth,
  genHeight,
  bgColor,
  bannersColor,
  header,
  footer,
  sidebar,
  sidebar2,
  popup,
}) => {
  return (
    <MDBox width={genWidth} height={genHeight}>
      <MDBox
        position="relative"
        py="2%"
        bgColor={bgColor}
        borderRadius="sm"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        sx={{
          justifyContent: 'space-around',
          fontSize: '25px',
          boxShadow: 3,
          border: '1px solid #adb5bd',
        }}
      >
        <MDBox
          mx="auto"
          bgColor={header ? bannersColor : bgColor}
          borderRadius="sm"
          width="90%"
          height="20%"
          sx={{ border: `1px solid ${header ? 'grey' : '#ced4da'}` }}
        />
        {/*{popup ? (*/}
        {/*  <AnnouncementIcon*/}
        {/*    color={bannersColor}*/}
        {/*    sx={{*/}
        {/*      position: 'absolute',*/}
        {/*      right: '20%',*/}
        {/*      top: '25%',*/}
        {/*    }}*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  <AnnouncementOutlinedIcon*/}
        {/*    sx={{*/}
        {/*      color: '#ced4da',*/}
        {/*      position: 'absolute',*/}
        {/*      right: '20%',*/}
        {/*      top: '25%',*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        <MDBox
          mx="5%"
          bgColor={sidebar ? bannersColor : bgColor}
          borderRadius="sm"
          width="20%"
          height="50%"
          sx={{ border: `1px solid ${sidebar ? 'grey' : '#ced4da'}` }}
        />
        <MDBox
          sx={{
            position: 'absolute',
            right: '20%',
            top: '25%',
          }}
          bgColor={sidebar2 ? bannersColor : bgColor}
          borderRadius="sm"
          width="20%"
          height="50%"
          sx={{
            border: `1px solid ${sidebar ? 'grey' : '#ced4da'}`,
            position: 'absolute',
            right: '5%',
            top: '25%',
          }}
        />
        <MDBox
          mx="auto"
          bgColor={footer ? bannersColor : bgColor}
          borderRadius="sm"
          width="90%"
          height="20%"
          sx={{ border: `1px solid ${footer ? 'grey' : '#ced4da'}` }}
        ></MDBox>
      </MDBox>
    </MDBox>
  );
};

export default BannersDisplay;
