import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDCg2TD4Up1RtSejzWSuroUZDdHnGalKkc',
  authDomain: 'xstreamtraffic-29567.firebaseapp.com',
  projectId: 'xstreamtraffic-29567',
  storageBucket: 'xstreamtraffic-29567.appspot.com',
  messagingSenderId: '273654752529',
  appId: '1:273654752529:web:06dd6b5bd35854f67958b0',
  measurementId: 'G-JWK192Q8M0',
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
export const db = getFirestore();
export const auth = getAuth();
