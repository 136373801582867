/**
 * External dependencies
 */
import React, { useState, useMemo } from 'react';

/**
 * Internal dependencies
 */
import AuthContext from './context';
import useLocalStorage from './use-LocalStorage';

export const AuthProvider = ({ children }) => {
  const [sessionToken, setSessionToken, removeSessionToken] = useLocalStorage(
    'sessionToken',
    null
  );

  const isLoggedIn = useMemo(() => !!sessionToken, [sessionToken]);

  return (
    <AuthContext.Provider
      value={{
        sessionToken,
        setSessionToken,
        removeSessionToken,
        isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
