import MDButton from '../../../components/MDButton';
import MDTypography from '../../../components/MDTypography';
import React from 'react';
import { toast } from 'react-toastify';

const handleDetails = () => {
  toast('Temporarily unavailable ...');
};

const columns = [
  {
    Header: 'created on',
    accessor: (voucher) => {
      const createDate = new Date(voucher.createdAt);
      return (
        <>
          <MDTypography variant="caption">
            {createDate.toLocaleDateString()}
          </MDTypography>
          <br />
          <MDTypography variant="caption">
            {createDate.toLocaleTimeString()}
          </MDTypography>
        </>
      );
    },
  },
  { Header: 'code', accessor: 'code' },
  { Header: 'value', accessor: 'value' },
  {
    Header: 'redeemed on',
    accessor: (voucher) => {
      if (!voucher.redeemedAt) return null;
      const redeemDate = new Date(voucher.redeemedAt);
      return (
        <>
          <MDTypography variant="caption">
            {redeemDate.toLocaleDateString()}
          </MDTypography>
          <br />
          <MDTypography variant="caption">
            {redeemDate.toLocaleTimeString()}
          </MDTypography>
        </>
      );
    },
  },
  {
    Header: 'beneficiary',
    accessor: (voucher) => {
      return <MDTypography variant="caption">{voucher.userName}</MDTypography>;
    },
  },
  {
    Header: 'actions',
    accessor: 'actions',
    Cell: ({ row }) => {
      return (
        <MDButton
          sx={{ px: '7px' }}
          variant="outlined"
          color="info"
          size="small"
          onClick={handleDetails}
        >
          details
        </MDButton>
      );
    },
  },
];
export default columns;
