/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const EDIT_PARTNER = gql`
  mutation EditPartner($partnerId: String, $input: AddPartnerInput) {
    editPartner(input: $input, partnerId: $partnerId) {
      _id
      name
      logo
      homepage
    }
  }
`;
