import { Grid, Modal, CardContent, Stack, Input, Button } from '@mui/material';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../components/DashboardNavbar';
import Footer from '../../../components/Footer';
import PlatformCard from './PlatformCard';
import React, { useState, useEffect } from 'react';
import pxToRem from '../../../assets/theme/functions/pxToRem';
import MDInput from 'components/MDInput';
import useAllPartners from '../../../utils/hooks/use-allPartners';
import useAddPartner from '../../../utils/hooks/useAddPartner';
import useEditPartner from '../../../utils/hooks/useEditPartner';
import useRemovePartner from '../../../utils/hooks/useRemovePartner';
import uploadFile from '../../../utils/firebase/uploadFile';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const PlatformsBoard = () => {
  const partners = useAllPartners()?.data?.allPartners ?? [];
  const [open, setOpen] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [photoURL, setPhotoURL] = useState('');

  const { addPartner } = useAddPartner();
  const { editPartner } = useEditPartner();
  const { removePartner } = useRemovePartner();

  //target partner
  const [targetId, setTargetId] = useState();
  const [targetName, setTargetName] = useState('');
  const [targetHomepage, setTargetHomepage] = useState('');
  const [targetLogo, setTargetLogo] = useState('');

  useEffect(() => {
    // create the preview
    let objectUrl;
    if (file) {
      objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    }
    // free memory when ever this component is unmounted
    return () => objectUrl && URL.revokeObjectURL(objectUrl);
  }, [file]);

  //modal handlers
  const handleEdit = (id) => {
    setFile(null);
    setPreview(null);
    setTarget(id);
    setOpen(true);
  };
  const handleClose = () => {
    setFile(null);
    setPreview(null);
    setOpen(false);
  };

  const handleUpdate = async () => {
    let logo = '';
    if (targetId === '') {
      if (file) {
        logo = await uploadFile(
          file,
          `partners/${targetName}/${file?.name || 'Unnamed'}`
        );
      }
      addPartner({
        variables: {
          input: {
            name: targetName,
            logo,
            homepage: targetHomepage,
          },
        },
      });
    } else {
      if (file) {
        logo = await uploadFile(
          file,
          `partners/${targetName}/${file?.name || 'Unnamed'}`
        );
      } else {
        logo = partners.find((p) => p._id === targetId)?.logo || '';
      }
      editPartner({
        variables: {
          partnerId: targetId,
          input: {
            name: targetName,
            logo,
            homepage: targetHomepage,
          },
        },
      });
    }
    setOpen(false);
  };

  const setTarget = (id) => {
    setTargetId(id);
    setTargetName(partners.find((p) => p._id === id)?.name || '');
    setTargetHomepage(partners.find((p) => p._id === id)?.homepage || '');
    setTargetLogo(partners.find((p) => p._id === id)?.logo || '');
  };

  const handleDelete = (id) => {
    setTargetId(id);
    setOpenDelModal(true);
  };
  const handleCloseDelModal = () => setOpenDelModal(false);
  const deletePartner = () => {
    removePartner({
      variables: {
        partnerId: targetId,
      },
    });
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setPhotoURL(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={5} mb={10} maxWidth={950} mx="auto">
          <Card
            sx={{
              pb: pxToRem(25),
              maxHeight: { sm: '755px' },
            }}
          >
            <MDBox
              mt={-3}
              mx={4}
              bgColor="info"
              borderRadius="lg"
              minHeight="80px"
              textAlign="center"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MDTypography variant="h3" color="light" fontWeight="regular">
                Partners
              </MDTypography>
            </MDBox>
            <MDBox
              px={4}
              pt={2.5}
              pb={1.5}
              lineHeight={1}
              display="flex"
              alignItems="center"
              sx={{ justifyContent: { xs: 'center', sm: 'flex-end' } }}
            >
              <MDButton
                variant="contained"
                color="info"
                sx={{ width: { xs: '100%', sm: 'auto' } }}
                onClick={() => {
                  setTargetName('');
                  handleEdit('');
                }}
              >
                Add Platform
              </MDButton>
            </MDBox>
            <MDBox
              py={1}
              sx={{
                overflow: { sm: 'scroll' },
              }}
            >
              <Grid container px={4} spacing={2} justifyContent="center">
                {partners.map((partner) => {
                  return (
                    <Grid item xs={12} sm={3} key={partner._id}>
                      <PlatformCard
                        partner={partner}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { sm: '425px' } }}>
          <CardContent>
            <MDBox my={1}>
              <MDTypography variant="h6" color="secondary">
                Add/Edit site
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <MDBox
                component="img"
                src={preview || targetLogo}
                style={{ maxHeight: 200, maxWidth: 200 }}
              ></MDBox>
              <Stack
                ml={1}
                alignItems="flex-start"
                justifyContent="space-around"
              >
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    sx={{
                      visibility: 'hidden',
                      position: 'absolute',
                      width: 0,
                      height: 0,
                    }}
                    onChange={handleImage}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    sx={{ color: 'white !important' }}
                  >
                    Upload
                  </Button>
                </label>
              </Stack>
            </MDBox>
            <MDBox>
              <MDInput
                variant="standard"
                label="Partner name"
                type="text"
                fullWidth
                value={targetName}
                onChange={(e) => setTargetName(e.target.value)}
              />
              <MDInput
                variant="standard"
                label="Homepage"
                type="text"
                fullWidth
                value={targetHomepage}
                onChange={(e) => setTargetHomepage(e.target.value)}
              />
            </MDBox>
          </CardContent>
          <MDBox px={3} mb={2} display="flex" justifyContent="space-between">
            <MDButton variant="gradient" color="dark" onClick={handleUpdate}>
              Save
            </MDButton>
            <MDButton variant="gradient" color="dark" onClick={handleClose}>
              Cancel
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
      <Modal
        open={openDelModal}
        onClose={handleCloseDelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { sm: 425 }, minHeight: 155 }}>
          <MDBox textAlign="center" mt={4} sx={{ px: { xs: 0.25 } }}>
            <MDTypography variant="h6" color="secondary">
              Are you sure you want to delete this site?
            </MDTypography>
          </MDBox>
          <MDBox mt={3} px={3} display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="dark"
              onClick={() => {
                deletePartner(targetId);
                handleCloseDelModal();
              }}
            >
              Delete
            </MDButton>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={handleCloseDelModal}
            >
              Cancel
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
    </>
  );
};

export default PlatformsBoard;
