/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stepper from '@mui/material/Stepper';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Footer from 'components/Footer';
import MDAvatar from 'components/MDAvatar';
import MyProfileEdit from './MyProfileEdit';
import { Icon, IconButton } from '@mui/material';
import ModalAvatar from './ModalAvatar';
import { useParams } from 'react-router-dom';
import { useAllUsers } from '../../../utils/hooks/use-allUsers';
import LoadingBasic from '../../../components/LoadingBasic';
import { UserProfileProvider } from './userProfileContext';

function UserProfile() {
  const { id: userId } = useParams();
  const { data, loading, error } = useAllUsers({ _id_contains: userId });
  const currentUser =
    data?.allUsers?.edges?.find((user) => user._id === userId) ?? {};

  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <ModalAvatar modalShow={modalShow} setModalShow={setModalShow} />
      <MDBox maxWidth={950} minHeight={{ md: '70vh', lg: '90vh' }} mx="auto">
        <Grid container justifyContent="center" sx={{ my: 4, marginTop: '0' }}>
          <Grid item xs={12}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="regular">
                  Profile Page
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card sx={{ minHeight: '60vh' }}>
              <MDBox mt={-3} mx={2}>
                <Stepper
                  sx={{
                    maxWidth: { xs: '95%', md: '40%' },
                    justifyContent: 'center',
                    margin: '0 auto',
                  }}
                >
                  <MDBox position="relative">
                    <MDAvatar
                      src={currentUser?.profile?.avatar}
                      size="xxl"
                      variant="rounded"
                      sx={{ borderRadius: '50%' }}
                    />
                    <IconButton
                      color="white"
                      onClick={() => setModalShow(true)}
                      sx={{
                        position: 'absolute',
                        right: '0',
                        bottom: '0',
                        bgcolor: '#6c757d',
                      }}
                    >
                      <Icon fontSize="small">edit</Icon>
                    </IconButton>
                  </MDBox>
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                {loading ? (
                  <LoadingBasic />
                ) : (
                  <MyProfileEdit userData={currentUser} />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </>
  );
}

const UserProfileWrapper = () => {
  return (
    <DashboardLayout>
      <UserProfileProvider>
        <UserProfile />
      </UserProfileProvider>
    </DashboardLayout>
  );
};

export default UserProfileWrapper;
