export const categories = ['FEMALE', 'MALE', 'GAY', 'TRANS', 'COUPLE'];
export const subCat = [
  'Sub1',
  'Sub2',
  'Sub3',
  'Sub4',
  'Sub5',
  'Sub6',
  'Sub7',
  'Sub8',
  'Sub9',
];
