/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: 'sign-in-form',
  formField: {
    email: {
      name: 'email',
      label: 'E-mail',
      type: 'email',
      errorMsg: 'Email is required.',
    },
    password: {
      name: 'password',
      label: 'Password',
      type: 'password',
      errorMsg: 'Password is required.',
    },
  },
};
